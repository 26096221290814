import productService from '~/services/products'
import fileService from '~/services/files'
import sellerService from '~/services/seller'
import lotService from '~/services/lots'
import offerService from '~/services/offers'
import orderService from '~/services/orders'
import catalogsService from '~/services/catalogs'
import currency from '~/services/currency'
import users from '~/services/users'
import report from '~/services/report'
import product from '~/services/product'
import buyer from '~/services/buyer'
import acl from '~/services/acl'
import posthogClient from '~/services/posthog-client'
import batches from '~/services/batches'

export default (app, inject) => {
  const { $axios, $posthog, $config } = app
  const services = {
    ...productService($axios, $posthog),
    ...fileService($axios, $posthog),
    ...sellerService($axios, $posthog),
    ...lotService($axios, $posthog),
    ...offerService($axios, $posthog),
    ...orderService($axios, $posthog),
    ...catalogsService($axios, $posthog),
    ...currency($axios, $posthog),
    ...users($axios, $posthog),
    ...report($axios, $posthog),
    ...product($axios, $posthog),
    ...buyer($axios, $posthog),
    ...acl($axios, $posthog),
    ...posthogClient($config),
    ...batches($axios, $config, $posthog),
  }
  const methods = Object.keys(services)
  methods.forEach((method) => {
    inject(method, services[method])
  })
}
