import * as qs from 'querystring'
import urlencode from 'urlencode'

export default ($axios) => {
  return {
    getProducts: async (params) => {
      let filter = ''
      if (params.filter.length > 0) {
        params.filter.forEach(function (item) {
          if (item.value) {
            if (
              !(
                (item.field.name === 'shelfLifeRemainingDay' ||
                  item.field.name === 'priority') &&
                item.value === '1'
              )
            ) {
              item.field.op = item.value === '1' ? 'gte' : item.field.op
              filter +=
                '&filter=' +
                item.field.type +
                ':' +
                item.field.name +
                ':' +
                item.field.op +
                ':' +
                urlencode(item.value)
            }

            if (item.maxvalue) {
              filter +=
                '&filter=' +
                item.field.type +
                ':' +
                item.field.name +
                ':lte:' +
                urlencode(item.maxvalue)
            }
          }
          // special cases
          if (item.field.name === 'shelfLifeRemainingDay') {
            switch (item.value) {
              case '90':
                filter += '&filter=number:shelfLifeRemainingDay:gt:30'
                break
              case '180':
                filter += '&filter=number:shelfLifeRemainingDay:gt:90'
                break
              case '365':
                filter += '&filter=number:shelfLifeRemainingDay:gt:180'
                break
              case '1':
                filter += '&filter=number:shelfLifeRemainingDay:gt:365'
            }
          }
          if (item.field.name === 'priority') {
            switch (item.value) {
              case '365':
                filter += '&filter=number:priority:gt:180'
                break
              case '1':
                filter += '&filter=number:priority:gt:365'
            }
          }
        })
      }
      const res = await $axios.get(
        `/v2/products?${qs.stringify({
          ...params,
        })}${filter}`
      )
      return res
    },

    saveProduct: async (data) => {
      const req = await $axios.post(`/products`, data)
      return req
    },
  }
}
