import urlencode from 'urlencode'

export default ($axios) => {
  return {
    parseManifestOffer: async (params) => {
      const { data } = await $axios.post('/offers/parse-manifest-file', params)
      return data
    },
    validateRequiredOffer: async (params) => {
      const { data } = await $axios.post('/offers/validate-required', params)
      return data
    },
    bulkInsertOffer: async (data) => {
      const { data: reqData } = await $axios.post('/offers/bulk-insert', {
        data,
      })
      return reqData
    },
    saveOfferBatchDraft: async (data) => {
      const { data: reqData } = await $axios.put('/offers/save-draft', {
        data,
      })
      return reqData
    },
    submitOfferBatch: async (id, status, data) => {
      const { data: reqData } = await $axios.put(`/offers/${status}/${id}`, {
        data,
      })
      return reqData
    },
    getAllOffers: async (params) => {
      let filter = ''
      if (params.filter.length > 0) {
        params.filter.forEach(function (item) {
          if (item.value) {
            item.field.op = item.value === '1' ? 'gte' : item.field.op
            filter +=
              '&filter=' +
              item.field.type +
              ':' +
              item.field.name +
              ':' +
              item.field.op +
              ':' +
              urlencode(item.value)

            if (item.maxvalue) {
              filter +=
                '&filter=' +
                item.field.type +
                ':' +
                item.field.name +
                ':lte:' +
                urlencode(item.maxvalue)
            }
          }
        })
      }
      const res = await $axios.get(
        '/offers?page=' +
          params.page +
          '&size=' +
          params.size +
          '&order=' +
          params.order +
          '&orderby=' +
          params.orderby +
          '&search=' +
          urlencode(params.search) +
          '&field=' +
          params.field +
          filter
      )
      return res
    },
    getOfferDetail: async (params) => {
      const { data } = await $axios.get('/offers/' + params)
      return data
    },
  }
}
