import * as qs from 'querystring'
import urlencode from 'urlencode'
import { POSTHOG_EVENT_NAMES } from '~/utils/posthog-constant'
export default ($axios, $posthog) => {
  return {
    parseManifest: async (params) => {
      const { data } = await $axios.post(
        '/products/parse-manifest-file',
        params
      )
      return data
    },
    validateRequired: async (params) => {
      const { data } = await $axios.post('/products/validate-required', params)
      return data
    },
    validateDuplicate: async (params) => {
      const { data } = await $axios.post('/products/validate-duplicate', params)

      return data
    },
    downloadLogs: async (products, template, logType) => {
      const { data } = await $axios.post(
        `/batches/download-log?include=${logType}`,
        {
          data: products,
          template,
        }
      )

      return data
    },
    bulkInsert: async (data, template, isUpdate) => {
      $posthog.capture(POSTHOG_EVENT_NAMES.BULK_UPLOAD_INVENTORY, template)

      const { data: reqData } = await $axios.post(`/products/bulk-insert`, {
        data,
        template,
        isUpdate,
      })
      return reqData
    },
    getAllProducts: async (params) => {
      let filter = ''
      if (params?.filter.length > 0) {
        params.filter.forEach(function (item) {
          if (item.value) {
            if (
              !(
                (item.field.name === 'shelfLifeRemainingDay' ||
                  item.field.name === 'priority') &&
                item.value === '1'
              )
            ) {
              item.field.op = item.value === '1' ? 'gte' : item.field.op
              filter +=
                '&filter=' +
                item.field.type +
                ':' +
                item.field.name +
                ':' +
                item.field.op +
                ':' +
                urlencode(item.value)
            }

            if (item.maxvalue) {
              filter +=
                '&filter=' +
                item.field.type +
                ':' +
                item.field.name +
                ':lte:' +
                urlencode(item.maxvalue)
            }
          }
          // special cases
          if (item.field.name === 'shelfLifeRemainingDay') {
            switch (item.value) {
              case '90':
                filter += '&filter=number:shelfLifeRemainingDay:gt:30'
                break
              case '180':
                filter += '&filter=number:shelfLifeRemainingDay:gt:90'
                break
              case '365':
                filter += '&filter=number:shelfLifeRemainingDay:gt:180'
                break
              case '1':
                filter += '&filter=number:shelfLifeRemainingDay:gt:365'
            }
          }
          if (item.field.name === 'priority') {
            switch (item.value) {
              case '365':
                filter += '&filter=number:priority:gt:180'
                break
              case '1':
                filter += '&filter=number:priority:gt:365'
            }
          }
        })
      }
      $posthog.capture(POSTHOG_EVENT_NAMES.GET_ALL_PRODUCT, {
        ...params,
        filter: params?.filter
          .map((f) => `${f.field.label}: ${f.value}`)
          .join(', '),
      })

      const res = await $axios.get(
        `/batches?${qs.stringify({
          ...params,
        })}${filter}`
      )
      return res
    },
    getBatch: async (params) => {
      const { data } = await $axios.get('/batches/' + params)
      return data
    },
    getProductCategories: async (params) => {
      const { data } = await $axios.get(
        `/products/categories/all?${qs.stringify({
          ...params,
        })}`
      )
      return data
    },
    getProductBrands: async (params) => {
      const { data } = await $axios.get(
        `/products/brands/all?${qs.stringify({
          ...params,
        })}`
      )
      return data
    },
    calculateSelectedProducts: async (productIds) => {
      const { data } = await $axios.post('/batches/calculate-selected', {
        productIds,
      })

      return data
    },
    deleteSelectedProducts: async (productIds) => {
      return await $axios.post('/batches/bulk-delete', {
        productIds,
      })
    },
    exportSelectedProducts: async (productIds, headers) => {
      const { data } = await $axios.post('/batches/export-data', {
        productIds,
        headers,
      })

      return data
    },
    updateBulkProducts: async (products) => {
      $posthog.capture(POSTHOG_EVENT_NAMES.UPDATE_INVENTORY, products)
      const updatePromiseProducts = products.map((product) => {
        const { id } = product
        delete product.id
        return $axios.put(`/batches/${id}`, product)
      })

      await Promise.all(updatePromiseProducts)
    },
    getProductCountries: async () => {
      const { data } = await $axios.get('products/countries/all')
      return data
    },
    getProductCountriesCurrency: async () => {
      const { data } = await $axios.get('products/countries/currency')
      return data
    },
    getTopPriorities: async () => {
      const { data } = await $axios.get('/batches/priorities')
      return data
    },
    getSlobIndex: async (params) => {
      let filter = ''
      if (params) {
        if (params.category) {
          const item = params.category
          filter += '&category=' + urlencode(item.value)
        }
        if (params.sub_category) {
          const item = params.sub_category
          filter += '&sub_category=' + urlencode(item.value)
        }
      }
      const { data } = await $axios.get(
        `/reports/slob-index?${filter.substring(1)}`
      )
      return data
    },

    getProducts: async (params) => {
      let filter = ''
      if (params.filter.length > 0) {
        params.filter.forEach(function (item) {
          if (item.value) {
            if (
              !(
                (item.field.name === 'shelfLifeRemainingDay' ||
                  item.field.name === 'priority') &&
                item.value === '1'
              )
            ) {
              item.field.op = item.value === '1' ? 'gte' : item.field.op
              filter +=
                '&filter=' +
                item.field.type +
                ':' +
                item.field.name +
                ':' +
                item.field.op +
                ':' +
                urlencode(item.value)
            }

            if (item.maxvalue) {
              filter +=
                '&filter=' +
                item.field.type +
                ':' +
                item.field.name +
                ':lte:' +
                urlencode(item.maxvalue)
            }
          }
          // special cases
          if (item.field.name === 'shelfLifeRemainingDay') {
            switch (item.value) {
              case '90':
                filter += '&filter=number:shelfLifeRemainingDay:gt:30'
                break
              case '180':
                filter += '&filter=number:shelfLifeRemainingDay:gt:90'
                break
              case '365':
                filter += '&filter=number:shelfLifeRemainingDay:gt:180'
                break
              case '1':
                filter += '&filter=number:shelfLifeRemainingDay:gt:365'
            }
          }
          if (item.field.name === 'priority') {
            switch (item.value) {
              case '365':
                filter += '&filter=number:priority:gt:180'
                break
              case '1':
                filter += '&filter=number:priority:gt:365'
            }
          }
        })
      }
      const res = await $axios.get(
        `/products?${qs.stringify({
          ...params,
        })}${filter}`
      )
      return res
    },

    saveProduct: async (data) => {
      const req = await $axios.post(`/products`, data)
      return req
    },

    updateProduct: async (data) => {
      const req = await $axios.put(`/products/${data.id}`, data)
      return req
    },

    getProduct: async (params) => {
      const { data } = await $axios.get('/products/' + params)
      return data
    },
    saveBatch: async (data) => {
      const sellerId = data.sellerId
      const { data: reqData } = await $axios.post(
        `/sellers/${sellerId}/batches`,
        data
      )
      return reqData
    },
    updateBatch: async (data) => {
      const sellerId = data.sellerId
      const { data: reqData } = await $axios.put(
        `/sellers/${sellerId}/batches/${data.id}`,
        data
      )
      return reqData
    },
    saveProductImage: async (params) => {
      const res = await $axios.post('/products/images', params)
      return res.data
    },
    deleteProductImage: async (params) => {
      const { data } = await $axios.delete('/products/images/' + params)
      return data
    },
    createProductCategories: async (params) => {
      const { data } = await $axios.post('/products/categories', params)
      return data
    },
    deleteProductCategories: async (params) => {
      const { data } = await $axios.post('/products/categories', params)
      return data
    },
    updateSingleProductCategories: async (params) => {
      const data = await $axios.put(
        `/products/categories/${params.categoryId}`,
        params
      )
      return data
    },
    createSubCategories: async (params) => {
      const { data } = await $axios.post('/products/subcategories', params)
      return data
    },
    deleteSubCategories: async (params) => {
      return await $axios.delete('/products/subcategories/' + params)
    },
    createProductBrands: async (params) => {
      const { data } = await $axios.post('/products/brands', params)
      return data
    },
    deleteProductBrands: async (params) => {
      return await $axios.delete('/products/brands/' + params)
    },
    updateProductBrand: async (params) => {
      return await $axios.put('/products/brands/' + params.id, params)
    },
  }
}
