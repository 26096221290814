import {
  GET_ROLES,
  SET_ROLES,
  STOP_LOADING,
  SET_IMPERSONATION,
  UPDATE_IMPERSONATION,
  CANCEL_IMPERSONATION,
  SHOW_IMPERSONATION,
  SET_ALERT_NOTIFICATION,
  SHOW_ALERT_NOTIFICATION,
  SET_ALERT_NOTIFICATION_CODE,
} from '../utils/constant'
export const state = () => ({
  roles: [],
  impersonate: {
    active: false,
    seller: null,
    code: 'OK',
    message: '',
  },
})

export const getters = {
  roles(state) {
    return state.roles
  },
  impersonate(state) {
    return state.impersonate
  },
}
export const mutations = {
  [SET_ROLES](state, payload) {
    state.roles = payload
  },

  [SET_IMPERSONATION](state, payload) {
    state.impersonate = payload
  },

  [UPDATE_IMPERSONATION](state, payload) {
    state.impersonate = payload
  },

  [CANCEL_IMPERSONATION](state) {
    state.impersonate = {
      active: false,
      seller: null,
    }
  },
}

export const actions = {
  async [GET_ROLES]({ commit }) {
    try {
      const req = await this.$getRoles()
      commit(SET_ROLES, req)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },
  async [SET_IMPERSONATION]({ commit }, params) {
    try {
      const req = await this.$setImpersonation(params)
      if (req.code === 'IMPERSONATE_EXISTS') {
        commit(SET_ALERT_NOTIFICATION, req.message, { root: true })
        commit(SHOW_ALERT_NOTIFICATION, true, { root: true })
        commit(SET_ALERT_NOTIFICATION_CODE, req.code, { root: true })
        commit(SET_IMPERSONATION, {
          active: true,
          seller: sellerId,
        })
      } else {
        commit(SET_ALERT_NOTIFICATION, '', { root: true })
        commit(SET_ALERT_NOTIFICATION_CODE, '', { root: true })
        commit(SHOW_ALERT_NOTIFICATION, false, { root: true })
        commit(SHOW_IMPERSONATION, true, { root: true })
      }
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },
  async [CANCEL_IMPERSONATION]({ commit }) {
    try {
      await this.$cancelImpersonation()
      commit(SET_IMPERSONATION, {})
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },
}
