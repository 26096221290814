import {
  START_LOADING,
  STOP_LOADING,
  GET_STATISTICS,
  GET_TOP_PRIORITIES,
  GET_SLOB_INDEX,
  GET_DATE_LAST_UPLOADED,
} from '../utils/constant'
import { SENTRY_EVENT_NAMES } from '~/utils/sentry-constant'
export const state = () => ({
  // {
  //     title: 'SKU Number',
  //     value: '$53,000',
  //     percentage: '+55%',
  //     iconClass: 'mdi-counter',
  //     detail: 'since last submission',
  //     iconBackground: 'bg-gradient-primary',
  //   },

  // {
  //     title: 'Total CBM',
  //     value: '$103,430',
  //     percentage: '+5%',
  //     iconClass: 'mdi-cash-multiple',
  //     iconBackground: 'bg-gradient-warning',
  //     detail: '',
  //   },
  statistics: [
    {
      title: 'Total Items',
      value: '2,300',
      percentage: '+3%',
      iconClass: 'mdi-package-variant-closed',
      iconBackground: 'bg-gradient-danger',
      detail: 'since last week',
    },
    {
      title: 'Avg Discount',
      value: '+3,462',
      percentage: '-2%',
      iconClass: 'mdi-sale-outline',
      percentageColor: 'text-danger',
      iconBackground: 'bg-gradient-success',
      detail: 'since last quarter',
    },
    {
      title: 'Total Retail Price',
      value: '$103,430',
      percentage: '+5%',
      iconClass: 'mdi-cash-multiple',
      iconBackground: 'bg-gradient-warning',
      detail: 'than last month',
    },
    {
      title: 'Total Asking Price',
      value: '$105,430',
      percentage: '+5%',
      iconClass: 'mdi-cash-multiple',
      iconBackground: 'bg-gradient-warning',
      detail: 'than last month',
    },
  ],
  topPriorities: [],
  dateLastUploaded: new Date(),
  slobIndex: [],
})

export const getters = {
  statistics(state) {
    return state.statistics
  },
  topPriorities(state) {
    return state.topPriorities
  },
  dateLastUploaded(state) {
    return state.dateLastUploaded
  },
  slobIndex(state) {
    return state.slobIndex
  },
}

export const mutations = {
  [GET_STATISTICS](state, payload) {
    state.statistics = payload
  },
  [GET_TOP_PRIORITIES](state, payload) {
    state.topPriorities = payload
  },
  [GET_SLOB_INDEX](state, payload) {
    state.slobIndex = payload
  },
  [GET_DATE_LAST_UPLOADED](state, payload) {
    state.dateLastUploaded = payload
  },
}

export const actions = {
  async [GET_TOP_PRIORITIES]({ commit }) {
    try {
      const data = await this.$getTopPriorities()
      // delete data.dateUploaded;
      commit(START_LOADING, true, { root: true })
      commit(GET_DATE_LAST_UPLOADED, data.dateLastUploaded)
      delete data.dateLastUploaded
      commit(GET_TOP_PRIORITIES, data)
      this.$sentry.captureMessage(SENTRY_EVENT_NAMES.GET_PRIORITY, {
        response: { data },
      })
    } catch (err) {
      this.$sentry.captureException(err)
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },
  async [GET_SLOB_INDEX]({ commit }, params) {
    try {
      const data = await this.$getSlobIndex(params)
      commit(START_LOADING, true, { root: true })
      commit(GET_SLOB_INDEX, data)
      this.$sentry.captureMessage(SENTRY_EVENT_NAMES.GET_SLOB_INDEX, {
        response: { data },
      })
    } catch (err) {
      this.$sentry.captureException(err)
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },
}
