import {
  GET_PRODUCT_CATEGORIES,
  SET_PRODUCT_IMPORTS,
  GET_PRODUCT_INVENTORY,
  START_LOADING,
  STOP_LOADING,
  SET_LOADING_TEXT,
  PARSE_VALIDATE_PRODUCTS,
  SET_PRODUCT_HEADERS,
  SET_PRODUCT_TEMPLATE,
  SET_PRODUCT_ERROR_SUMMARY,
  SET_PRODUCT_DUPLICATE_SUMMARY,
  DOWNLOAD_LOGS,
  SET_DOWNLOAD_LOG_URL,
  SORT_ARRAY,
  GET_PRODUCT_BRANDS,
  GET_PRODUCT_IMPORTS,
  SAVE_PRODUCT_IMPORTS,
  SAVE_PRODUCT_IMPORTS_COUNT,
  REPLACE_PRODUCT_IMPORTS,
  CALCULATE_SELECTED_PRODUCTS,
  COMPUTE_SELECTED_PRODUCTS,
  PRODUCTS_SELECTED,
  PRODUCTS_SELECTED_DIALOG,
  GET_PRODUCT_COUNTRIES,
  GET_PRODUCT_COUNTRIES_CURRENCY,
  PRODUCTS_LIST_DIALOG,
  SAVE_PRODUCT_CATALOG_COUNT,
  SAVE_PRODUCT_CATALOG,
  SAVE_SINGLE_PRODUCT,
  SAVE_PRODUCT_COUNT,
  SET_PRODUCT_PARAMS,
  SAVE_SINGLE_BATCH,
  SAVE_BATCH,
  UPDATE_SINGLE_BATCH,
  UPDATE_SINGLE_PRODUCT,
  PRODUCTS_IMAGE_UPLOAD_DIALOG,
  UPLOAD_PRODUCT_IMAGE_FILE_S3,
  SAVE_PRODUCT_IMAGE,
  SUCCESS,
  SET_FILE_STATUS,
  DELETE_PRODUCT_IMAGE,
  SET_IMAGE_ID,
  SET_PRODUCT_SELECTED,
  GET_PRODUCT_ALL,
  PRODUCT_BATCH_EXPAND,
} from '../utils/constant'
import { sortArray } from '../utils/helpers'

export const state = () => ({
  product: null,
  productImports: [],
  productInventory: [],
  productCategories: [],
  productSubCategories: [],
  productBrands: [],
  productTemplate: null,
  productHeaders: [],
  productErrorSummary: [],
  productDuplicateSummary: [],
  productDownloadLogUrl: '',
  productSavedImports: 0,
  productsSelected: [],
  productsSelectedDialog: false,
  productsListDialog: false,
  productSearch: [
    { text: 'Product Name', value: 'productName' },
    { text: 'SKU Name', value: 'skuName' },
    { text: 'SKU Number', value: 'skuNumber' },
  ],
  productFilter: [
    {
      name: 'productCategory',
      label: 'Category',
      type: 'string',
      op: 'eq',
    },
    {
      label: 'Warehouse Location',
      name: 'warehouseLocation',
      type: 'string',
      op: 'eq',
    },
    {
      label: 'Product Restricted Country',
      name: 'productRestrictedCountry',
      type: 'array',
      op: 'eq', 
    },
    {
      name: 'productSubCategory',
      label: 'Sub Category',
      type: 'string',
      op: 'eq',
    },
    { name: 'brand', label: 'Brand', type: 'string', op: 'eq' },
    {
      name: 'shelfLifeRemainingDay',
      label: 'Shelf Life',
      type: 'number',
      op: 'lte',
    },
    {
      name: 'priority',
      label: 'Priority',
      type: 'number',
      op: 'lte',
    },
    {
      name: 'discountPercentage',
      label: 'Discount',
      type: 'number',
      op: 'gte',
    },
    {
      name: 'sellerName',
      label: 'Seller Name',
      type: 'string',
      op: 'eq',
    },
    { name: 'updatedAt', label: 'Last Update', type: 'date', op: 'gte' },
    {
      name: 'productId',
      label: 'productId',
      type: 'string',
      op: 'eq',
      display: false,
    },
  ],
  productShelfLife: [
    { name: '30', label: '< 1 month' },
    { name: '90', label: '1-3 month' },
    { name: '180', label: '3-6 month' },
    { name: '365', label: '6-12 month' },
    { name: '1', label: 'Greater than year' },
  ],
  productPriority: [
    { name: '180', label: '(P1) < 6 Months' },
    { name: '365', label: '(P2) 6-12 Months' },
    { name: '1', label: '(P3) > 1 Year' },
  ],
  calculateSelectedProducts: {
    totalCbm: 0.0,
    packageQuantity: 0,
    totalGrossWeight: 0.0,
    totalAskingPrice: 0.0,
    totalRetailPrice: 0.0,
    costRecovery: 0.0,
  },
  defaultBatchtHeader: [
    {
      text: 'Product Name',
      value: 'productName',
      sortable: false,
      width: '25%',
    },
    { text: 'SKU Number', value: 'skuNumber', sortable: false },
    { text: 'Product Category', value: 'productCategory', sortable: false },
    { text: 'Available Unit', value: 'quantity', sortable: false },
    // { text: 'Total Items', value: 'totalItems', sortable: false },
    {
      text: 'Shelf Life Remaining',
      value: 'shelfLifeRemainingDay',
      sortable: false,
    },
    {
      text: 'Asking Price',
      value: 'askingPrice',
      sortable: false,
    },
    {
      text: 'Discount Percentage',
      value: 'discountPercentage',
      sortable: false,
    },
    { text: 'Actions', value: 'actions', sortable: false },
  ],

  defaultBatchCatalogHeader: [
    {
      text: 'Product Name',
      value: 'productName',
      sortable: false,
      width: '30%',
    },
    { text: 'SKU Number', value: 'skuNumber', sortable: false },
    { text: 'Product Sub Category', value: 'productSubCategory' },
    { text: 'Available Unit', value: 'quantity', sortable: false },
    { text: 'Priority', value: 'priority', sortable: false },
    {
      text: 'Asking Price',
      value: 'askingPrice',
      sortable: false,
    },
    {
      text: 'Discount Percentage',
      value: 'discountPercentage',
      sortable: false,
    },
  ],
  defaultProductHeader: [
    { text: '', value: 'productName', sortable: false },
    { text: 'Actions', value: 'actions', sortable: false },
    { text: '', value: 'data-table-expand', width: '5%', sortable: false },
  ],
  defaultProductBatchHeader: [
    { text: 'Batch', value: 'id' },
    { text: '', value: 'data-table-expand' },
  ],
  hiddenProductHeader: ['variant', 'size', 'colour'],
  hiddenBatchHeader: [
    'proposedPrice',
    'proposedPriceUsd',
    'liquidationUrgency',
  ],
  barcodeUnits: ['EAN', 'UPC'],
  metricUnitsDimension: ['FT', 'IN', 'M', 'CM', 'MM'],
  metricUnitsWeight: [
    {
      key: 'LBS',
      name: 'Pound',
    },
    {
      key: 'ML',
      name: 'Miligram',
    },
    {
      key: 'G',
      name: 'Gram',
    },
    {
      key: 'KG',
      name: 'Kilogram',
    },
    {
      key: 'L',
      name: 'Litre',
    },
    {
      key: 'PC',
      name: 'Piece',
    },
    {
      key: 'PCS',
      name: 'Pieces',
    },
  ],
  metricUnitsSize: ['LBS', 'ML', 'G', 'PCS', 'KG', 'L', 'PC'],
  productType: ['Pallet', 'Cases', 'Carton', 'Box', 'Piece'],
  batchCondition: [
    'New',
    'Like New',
    'Uninspected Returns',
    'Good',
    'Very Good',
  ],
  productCondition: [
    'New',
    'Like New',
    'Uninspected Returns',
    'Good',
    'Very Good',
  ],
  productBulkAction: [
    {
      title: 'Single Upload',
      disabled: false,
      to: '/batch/view?type=create',
    },
    { title: 'Bulk Upload', disabled: false, to: '/batch/import/' },
  ],
  productCountries: [],
  productCountriesCurrency: {},
  productCatalogSaved: 0,
  productSaved: 0,
  productParams: {},
  batchSaved: [],
  productBatchHeaders: [
    'Batch ID',
    'Priority',
    'Package QTY',
    'Items QTY',
    'Asking Price',
    'Expiry Date',
    'Action',
  ],
  removeProductField: [
    'batches',
    'productImages',
    'totalUnits',
    'totalAskingPriceUsd',
    'totalQuantity',
    'averageDiscountPercentage',
    'priorities',
    'sellerName',
    'unitDimension',
  ],

  headersSelected: [],
  productImageUploadDialog: false,
  productFilterMultiSelect: ['brand', 'productCategory', 'productSubCategory'],
  productImage: [],
  prorductImageDelete: null,
  productCategoriesRaw: {},
  productCategoriesSearch: [{ text: 'Category Name', value: 'name' }],
  productBrandsRaw: {},
  productBrandsSearch: [{ text: 'Brand Name', value: 'name' }],
  productTemplateType: [
    {
      label: 'PRE-PACKAGE PRODUCT',
      value: 'PRE_PACKAGE_PRODUCT_TEMPLATE',
    },
    {
      label: 'NON PRE-PACKAGE PRODUCT',
      value: 'NON_PACKAGE_PRODUCT_TEMPLATE',
    },
  ],
  productAll: [],
  productBatchExpand: null,
})

export const getters = {
  productCategories(state) {
    return state.productCategories
  },
  productSubCategories(state) {
    return state.productSubCategories
  },
  productCategoriesRaw(state) {
    return state.productCategoriesRaw
  },
  productBrandsRaw(state) {
    return state.productBrandsRaw
  },
  productImports(state) {
    return state.productImports
  },
  productBrands(state) {
    return state.productBrands
  },
  productDuplicateSummary(state) {
    return state.productDuplicateSummary
  },
  productTemplate(state) {
    return state.productTemplate
  },
  productHeaders(state) {
    return state.productHeaders
  },
  productErrorSummary(state) {
    return state.productErrorSummary
  },
  productDownloadLogUrl(state) {
    return state.productDownloadLogUrl
  },
  productSearch(state) {
    return state.productSearch
  },
  productFilter(state) {
    return state.productFilter
  },
  calculateSelectedProducts(state) {
    return state.calculateSelectedProducts
  },
  productShelfLife(state) {
    return state.productShelfLife
  },
  productPriority(state) {
    return state.productPriority
  },
  defaultBatchtHeader(state) {
    return state.defaultBatchtHeader
  },
  productsSelected(state) {
    return state.productsSelected
  },
  productsSelectedDialog(state) {
    return state.productsSelectedDialog
  },
  barcodeUnits(state) {
    return state.barcodeUnits
  },
  metricUnitsDimension(state) {
    return state.metricUnitsDimension
  },
  metricUnitsWeight(state) {
    return state.metricUnitsWeight
  },
  metricUnitsSize(state) {
    return state.metricUnitsSize
  },
  productType(state) {
    return state.productType
  },
  batchCondition(state) {
    return state.batchCondition
  },
  productBulkAction(state) {
    return state.productBulkAction
  },
  productCountries(state) {
    return state.productCountries
  },
  productCountriesCurrency(state) {
    return state.productCountriesCurrency
  },
  productsListDialog(state) {
    return state.productsListDialog
  },
  defaultProductHeader(state) {
    return state.defaultProductHeader
  },
  hiddenProductHeader(state) {
    return state.hiddenProductHeader
  },
  defaultProductBatchHeader(state) {
    return state.defaultProductBatchHeader
  },
  productParams(state) {
    return state.productParams
  },
  hiddenBatchHeader(state) {
    return state.hiddenBatchHeader
  },
  productBatchHeaders(state) {
    return state.productBatchHeaders
  },
  removeProductField(state) {
    return state.removeProductField
  },
  headersSelected(state) {
    return state.headersSelected
  },
  productImageUploadDialog(state) {
    return state.productImageUploadDialog
  },
  productFilterMultiSelect(state) {
    return state.productFilterMultiSelect
  },
  productImage(state) {
    return state.productImage
  },
  prorductImageDelete(state) {
    return state.prorductImageDelete
  },
  productCategoriesSearch(state) {
    return state.productCategoriesSearch
  },
  productBrandsSearch(state) {
    return state.productBrandsSearch
  },
  productTemplateType(state) {
    return state.productTemplateType
  },
  productCondition(state) {
    return state.productCondition
  },
  productAll(state) {
    return state.productAll
  },
  defaultBatchCatalogHeader(state) {
    return state.defaultBatchCatalogHeader
  },
  productBatchExpand(state) {
    return state.productBatchExpand
  },
}
export const mutations = {
  [GET_PRODUCT_CATEGORIES](state, payload) {
    if (payload.items) {
      state.productCategoriesRaw = payload
    } else {
      state.productCategoriesRaw = payload
      state.productCategories = payload.map((sCat) => sCat.name)
      state.productSubCategories = payload
        .map((pLoad) => {
          return pLoad.subCategories.map((subCat) => {
            return {
              ...subCat,
              productCategoryName: pLoad.name,
            }
          })
        })
        .flat()
    }
  },
  [GET_PRODUCT_BRANDS](state, payload) {
    if (payload.items) {
      state.productBrandsRaw = payload
    } else {
      state.productBrandsRaw = payload
      state.productBrands = payload.map((brand) => brand.name)
    }
  },
  [GET_PRODUCT_IMPORTS](state, payload) {
    state.productImports = payload
  },
  [GET_PRODUCT_INVENTORY](state, payload) {
    state.productInventory = payload
  },
  [SET_PRODUCT_IMPORTS](state, payload) {
    const data = payload.map((imProduct) => {
      let errorObjs = {}
      if (imProduct.errors) {
        imProduct.errors.forEach((eP) => {
          errorObjs[eP.field] = eP.message
        })
      } else {
        errorObjs = null
      }
      return {
        ...imProduct,
        errors: errorObjs,
      }
    })
    state.productImports = data
  },
  [SET_PRODUCT_HEADERS](state, payload) {
    state.productHeaders = payload
  },
  [SET_PRODUCT_ERROR_SUMMARY](state, payload) {
    state.productErrorSummary = payload
  },
  [SET_PRODUCT_DUPLICATE_SUMMARY](state, payload) {
    state.productDuplicateSummary = payload
  },
  [SET_PRODUCT_TEMPLATE](state, payload) {
    state.productTemplate = payload
  },
  [SET_DOWNLOAD_LOG_URL](state, payload) {
    state.productDownloadLogUrl = payload
  },
  [SORT_ARRAY](state, payload) {
    state.productImports = sortArray(payload.data, payload.key)
  },
  [SAVE_PRODUCT_IMPORTS_COUNT](state, payload) {
    state.productSavedImports = payload
  },
  [REPLACE_PRODUCT_IMPORTS](state, payload) {
    state.productSavedImports = state.productSavedImports.splice(
      payload.findIndex,
      1,
      payload.newValue
    )
  },
  [CALCULATE_SELECTED_PRODUCTS](state, payload) {
    state.calculateSelectedProducts = payload
  },
  [PRODUCTS_SELECTED](state, payload) {
    state.productsSelected = payload
  },
  [PRODUCTS_SELECTED_DIALOG](state, payload) {
    state.productsSelectedDialog = payload
  },
  [PRODUCTS_LIST_DIALOG](state, payload) {
    state.productsListDialog = payload
  },
  [GET_PRODUCT_COUNTRIES](state, payload) {
    state.productCountries = payload
  },
  [GET_PRODUCT_COUNTRIES_CURRENCY](state, payload) {
    state.productCountriesCurrency = payload
  },
  [SAVE_PRODUCT_CATALOG_COUNT](state, payload) {
    state.productCatalogSaved = payload
  },
  [SAVE_PRODUCT_COUNT](state, payload) {
    state.productSaved = payload
  },
  [SAVE_BATCH](state, payload) {
    state.batchSaved = payload
  },

  [SET_PRODUCT_PARAMS](state, payload) {
    state.productParams = payload
  },
  [PRODUCTS_IMAGE_UPLOAD_DIALOG](state, payload) {
    state.productImageUploadDialog = payload
  },

  [SET_IMAGE_ID](state, payload) {
    if (payload) {
      state.productImage.push({
        fileId: payload.fileId,
        s3src: payload.uploadFileUrl.data.files.s3_path,
        imageSrc: payload.imageSrc,
        filename: payload.filename,
      })
    } else {
      state.productImage = []
    }
  },

  [SET_FILE_STATUS](state, payload) {
    state.fileStatus = payload
  },

  [DELETE_PRODUCT_IMAGE](state, payload) {
    state.productImage = state.productImage.filter(
      (arr) => arr.fileId !== payload.fileId
    )
  },
  [SET_PRODUCT_SELECTED](state, payload) {
    state.productsSelected = payload
  },
  [DELETE_PRODUCT_IMAGE](state, payload) {
    state.prorductImageDelete = payload
  },
  [GET_PRODUCT_ALL](state, payload) {
    state.productAll = payload
  },
  [PRODUCT_BATCH_EXPAND](state, payload) {
    state.productBatchExpand = payload
  },
}

export const actions = {
  async [GET_PRODUCT_COUNTRIES]({ commit }) {
    try {
      const { productCountries = [] } = await this.$getProductCountries()
      commit(START_LOADING, true, { root: true })
      commit(GET_PRODUCT_COUNTRIES, productCountries)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [GET_PRODUCT_COUNTRIES_CURRENCY]({ commit }) {
    try {
      const { productCountriesCurrency = {} } =
        await this.$getProductCountriesCurrency()
      commit(START_LOADING, true, { root: true })
      commit(GET_PRODUCT_COUNTRIES_CURRENCY, productCountriesCurrency)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [GET_PRODUCT_CATEGORIES]({ commit }, params) {
    try {
      const { productCategories = [] } = await this.$getProductCategories(
        params
      )
      commit(START_LOADING, true, { root: true })
      commit(GET_PRODUCT_CATEGORIES, productCategories)
      return productCategories
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [GET_PRODUCT_BRANDS]({ commit }, params) {
    try {
      const { productBrands = [] } = await this.$getProductBrands(params)
      commit(START_LOADING, true, { root: true })
      commit(GET_PRODUCT_BRANDS, productBrands)
      return productBrands
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [GET_PRODUCT_ALL]({ commit }, params) {
    try {
      commit(START_LOADING, true, { root: true })
      const data = await this.$getProducts(params)
      commit(GET_PRODUCT_ALL, data)
      return data
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [GET_PRODUCT_INVENTORY]({ commit }) {
    try {
      commit(START_LOADING, true, { root: true })
      const data = await this.$getAllProducts()
      commit(GET_PRODUCT_INVENTORY, data)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [PARSE_VALIDATE_PRODUCTS]({ commit }, fileId) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Parsing manifest file...', { root: true })
      const parsedData = await this.$parseManifest({ fileId })
      commit(SET_PRODUCT_TEMPLATE, parsedData.template)
      commit(SET_LOADING_TEXT, 'Validating required fields...', { root: true })
      const validateRequired = await this.$validateRequired(parsedData)
      commit(SET_PRODUCT_ERROR_SUMMARY, validateRequired.error_summary || [])
      commit(SET_PRODUCT_HEADERS, validateRequired.headers || [])
      commit(SET_LOADING_TEXT, 'Validating duplicate in manifest...', {
        root: true,
      })
      const validateDuplicate = await this.$validateDuplicate(validateRequired)
      commit(
        SET_PRODUCT_DUPLICATE_SUMMARY,
        validateDuplicate.error_duplicates || []
      )
      commit(SET_PRODUCT_IMPORTS, validateDuplicate.data)
    } catch (error) {
      return {
        error: true,
        message: error.message,
      }
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [DOWNLOAD_LOGS]({ commit }, params) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Exporting logs. Please wait...', { root: true })
      const req = await this.$downloadLogs(
        params.data,
        params.template,
        params.logType
      )
      commit(SET_DOWNLOAD_LOG_URL, req.url)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [SAVE_PRODUCT_IMPORTS]({ commit }, params) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Saving Imported Data. Please wait...', {
        root: true,
      })
      const req = await this.$bulkInsert(params.data, params.template, params.isUpdate)
      commit(SAVE_PRODUCT_IMPORTS_COUNT, req.productSaved)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [COMPUTE_SELECTED_PRODUCTS]({ commit }, productIds) {
    try {
      const data = await this.$calculateSelectedProducts(productIds)
      commit(CALCULATE_SELECTED_PRODUCTS, data)
    } catch (error) {
    } finally {
      // commit(STOP_LOADING, false, { root: true })
    }
  },

  async [SAVE_PRODUCT_CATALOG]({ commit }, params) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Saving Product Catalog. Please wait...', {
        root: true,
      })

      const req = await this.$saveProductCatalog(params.catalogDetails)
      commit(SAVE_PRODUCT_CATALOG_COUNT, req)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [SAVE_SINGLE_PRODUCT]({ commit }, params) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Saving Product. Please wait...', {
        root: true,
      })
      const req = await this.$saveProduct(params)
      commit(SAVE_PRODUCT_COUNT, req.data)
      return req
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },
  async [UPDATE_SINGLE_PRODUCT]({ commit }, params) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Updating Product. Please wait...', {
        root: true,
      })
      const req = await this.$updateProduct(params)
      commit(SAVE_PRODUCT_COUNT, req.data)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },
  async [SAVE_SINGLE_BATCH]({ commit }, params) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Saving Batch. Please wait...', {
        root: true,
      })
      const req = await this.$saveBatch(params)
      commit(SAVE_BATCH, req)
      return req
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },
  async [UPDATE_SINGLE_BATCH]({ commit }, params) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Updating Batch. Please wait...', {
        root: true,
      })
      const req = await this.$updateBatch(params)
      commit(SAVE_BATCH, req)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [UPLOAD_PRODUCT_IMAGE_FILE_S3]({ commit }, payload) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Generating secure url...', { root: true })
      const { filename, contentType, file, imageSrc } = payload
      const { url, fileId } = await this.$uploadUrl({ filename, contentType })
      commit(SET_LOADING_TEXT, 'Uploading file...', { root: true })
      const uploadFile = await this.$uploadFileToServer(url, file)
      commit(SET_FILE_STATUS, true)
      if (uploadFile) {
        commit(SET_LOADING_TEXT, 'Updating file status...', { root: true })
        const uploadFileUrl = await this.$updateFileStatus({
          fileId,
          status: SUCCESS,
        })
        if (uploadFileUrl)
          commit(SET_IMAGE_ID, { fileId, uploadFileUrl, imageSrc, filename })
      }
    } catch (error) {
      commit(SET_FILE_STATUS, false, { root: true })
      throw error
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [SAVE_PRODUCT_IMAGE]({ commit }, params) {
    try {
      commit(START_LOADING, true, { root: true })
      const req = await this.$saveProductImage(params.imageDetails)
      commit(GET_PRODUCT_INVENTORY, req)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },
  async [DELETE_PRODUCT_IMAGE]({ commit }, params) {
    try {
      const req = await this.$deleteProductImage(params.fileId)
      commit(DELETE_PRODUCT_IMAGE, req)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },
}
