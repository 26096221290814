import { sortArray } from '../utils/helpers'
import {
  GET_OFFER_IMPORTS,
  GET_OFFER_LIST,
  OFFERS_SELECTED_DIALOG,
  OFFERS_SELECTED,
  VALIDATE_OFFERS,
  PARSE_VALIDATE_OFFERS,
  REPLACE_OFFER_IMPORTS,
  SET_LOADING_TEXT,
  SET_OFFER_ERROR_SUMMARY,
  SET_OFFER_HEADERS,
  SET_OFFER_IMPORTS,
  SORT_ARRAY,
  START_LOADING,
  STOP_LOADING,
  SAVE_OFFER_IMPORTS,
  SAVE_OFFER_IMPORTS_COUNT,
  OFFERS_LIST_DIALOG,
  SET_OFFER_BATCH,
  SAVE_OFFER_BATCH_DRAFT,
  SUBMIT_OFFER_BATCH,
} from '~/utils/constant'

export const state = () => ({
  offer: null,
  offerImports: [],
  offerList: [],
  offerTemplate: null,
  offerHeaders: [],
  offerErrorSummary: [],
  offerSavedImports: 0,
  offerSelected: [],
  offerSelectedDialog: false,
  offerListDialog: false,
  offerSearch: [
    { text: 'Offer ID', value: 'id' },
    { text: 'SKU Number', value: 'skuNumber' },
  ],
  offerFilter: [
    { name: 'createdAt', label: 'Offer Date', type: 'date', op: 'gte' },
    { name: 'updatedAt', label: 'Last Update', type: 'date', op: 'gte' },
    {
      name: 'buyer',
      label: 'Buyer ',
      type: 'string',
      op: 'eq',
    },
  ],
  defaultOfferHeader: [
    { text: 'Offer ID', value: 'id' },
    { text: 'Product Catalog ID', value: 'catalog' },
    { text: 'Buyer', value: 'buyer' },
    { text: 'Total Asking Price', value: 'totalAskingPriceUsd' },
    { text: 'Status', value: 'status' },
    { text: 'Offer Date', value: 'createdAt' },
    { text: 'Last Update', value: 'updatedAt' },
    { text: 'Created By', value: 'createdBy' },
    { text: 'Actions', value: 'actions', sortable: false },
  ],
  offerStatusList: [
    { text: 'created', value: 'Waiting for Seller Response' },
    { text: 'approved', value: 'Proceed to Order' },
    { text: 'rejected', value: 'Rejected by Seller' },
  ],
  offerBatchHeader: [
    { text: 'SKU Number', value: 'skuNumber' },
    { text: 'Expiry date', value: 'expiryDate' },
    { text: 'QTY of package', value: 'quantity' },
    { text: 'Items Per Package', value: 'itemsPerPackageType' },
    { text: 'Total Items', value: 'totalItems' },
    { text: 'Shelf Life Remaining', value: 'shelfLifeRemainingDay' },
    { text: 'Priority', value: 'priority' },
    {
      text: 'Retail Price per Package (USD)',
      value: 'retailPricePerPackageType',
    },
    { text: 'Total Retail Price (USD)', value: 'totalRetailPriceUsd' },
    {
      text: 'Asking Price per Package (USD)',
      value: 'askingPricePerPackageType',
    },
    { text: 'Total Asking Price (USD)', value: 'totalAskingPriceUsd' },
    { text: 'Offer Price per package', value: 'offerPricePerPackage' },
    { text: 'QTY of package (offered)', value: 'quantity' },
    { text: '% Discount from Retail Price', value: 'offerPriceDiscountRetail' },
    { text: '% Discount from Asking Price', value: 'offerPriceDiscountAsking' },
    { text: 'Status', value: 'status' },
    // { text: 'Counter Price Per Package', value: 'counterPricePerPackage' },
  ],
  offerBatchData: [],
  offerBatchStatus: ['CREATED', 'APPROVED', 'REJECTED'],
  offerBatchRejectionRemarks: [
    'Price did not match',
    'Inventory not available',
    'Buyer location is not supported',
    'Others',
  ],
  offerBuyerInfo: [
    'Name',
    'Type',
    'Buyer Location',
    'Product Destination',
    'Document Verified',
  ],
  offerTab: [
    {
      name: 'all',
      label: 'All Offers ',
      type: 'string',
      op: 'eq',
    },
    {
      name: 'approved',
      label: 'Proceed to Order ',
      type: 'string',
      op: 'eq',
    },
    {
      name: 'rejected',
      label: 'Rejected ',
      type: 'string',
      op: 'eq',
    },
  ],
})

export const getters = {
  offerImports(state) {
    return state.offerImports
  },
  offerErrorSummary(state) {
    return state.offerErrorSummary
  },
  offerTemplate(state) {
    return state.offerTemplate
  },
  offerHeaders(state) {
    return state.offerHeaders
  },
  offerSearch(state) {
    return state.offerSearch
  },
  offerFilter(state) {
    return state.offerFilter
  },
  defaultOfferHeader(state) {
    return state.defaultOfferHeader
  },
  offerStatusList(state) {
    return state.offerStatusList
  },
  offerBatchHeader(state) {
    return state.offerBatchHeader
  },
  offerBatchData(state) {
    return state.offerBatchData
  },
  offerBatchStatus(state) {
    return state.offerBatchStatus
  },
  offerBatchRejectionRemarks(state) {
    return state.offerBatchRejectionRemarks
  },
  offerTab(state) {
    return state.offerTab
  },
  offerBuyerInfo(state) {
    return state.offerBuyerInfo
  },
}

export const mutations = {
  [GET_OFFER_LIST](state, payload) {
    state.offerList = payload
  },
  [GET_OFFER_IMPORTS](state, payload) {
    state.offerImports = payload
  },
  [SET_OFFER_IMPORTS](state, payload) {
    const data = payload.map((imOffer) => {
      let errorObjs = {}
      if (imOffer.errors) {
        imOffer.errors.forEach((eO) => {
          errorObjs[eO.field] = eO.message
        })
      } else {
        errorObjs = null
      }
      return {
        ...imOffer,
        errors: errorObjs,
      }
    })
    state.offerImports = data
  },
  [SET_OFFER_HEADERS](state, payload) {
    state.offerHeaders = payload
  },
  [SET_OFFER_ERROR_SUMMARY](state, payload) {
    state.offerErrorSummary = payload
  },
  [SORT_ARRAY](state, payload) {
    state.offerImports = sortArray(payload.data, payload.key)
  },
  [SAVE_OFFER_IMPORTS_COUNT](state, payload) {
    state.offerSavedImports = payload
  },
  [REPLACE_OFFER_IMPORTS](state, payload) {
    state.offerSavedImports = state.offerSavedImports.splice(
      payload.findIndex,
      1,
      payload.newValue
    )
  },
  [OFFERS_SELECTED](state, payload) {
    state.offersSelected = payload
  },
  [OFFERS_SELECTED_DIALOG](state, payload) {
    state.offersSelectedDialog = payload
  },
  [OFFERS_LIST_DIALOG](state, payload) {
    state.offersListDialog = payload
  },
  [SET_OFFER_BATCH](state, payload) {
    state.offerBatchData = payload
  },
}

export const actions = {
  async [GET_OFFER_LIST]({ commit }) {
    try {
      commit(START_LOADING, true, { root: true })
      const data = await this.$getAllOffers()
      commit(GET_OFFER_LIST, data)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [VALIDATE_OFFERS]({ commit }, payload) {
    try {
      commit(START_LOADING, true, { root: true })
      payload.data = payload.data.map((offer) => {
        delete offer.id
        delete offer.errors
        return offer
      })
      commit(SET_LOADING_TEXT, 'Validating manifest file...', { root: true })
      const validateRequired = await this.$validateRequiredOffer(payload)
      commit(SET_OFFER_ERROR_SUMMARY, validateRequired.error_summary || [])
      commit(SET_OFFER_HEADERS, validateRequired.headers || [])
      commit(SET_OFFER_IMPORTS, validateRequired.data)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [PARSE_VALIDATE_OFFERS]({ commit }, fileId) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Parsing manifest file...', { root: true })
      const parsedData = await this.$parseManifestOffer({ fileId })
      commit(SET_LOADING_TEXT, 'Validating manifest file...', { root: true })
      const validateRequired = await this.$validateRequiredOffer(parsedData)
      commit(SET_OFFER_ERROR_SUMMARY, validateRequired.error_summary || [])
      commit(SET_OFFER_HEADERS, validateRequired.headers || [])
      commit(SET_OFFER_IMPORTS, validateRequired.data)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [SAVE_OFFER_IMPORTS]({ commit }, params) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Saving Imported Data. Please wait...', {
        root: true,
      })
      const req = await this.$bulkInsertOffer(params.data, params.template)
      commit(SAVE_OFFER_IMPORTS_COUNT, req.offerSaved)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [SAVE_OFFER_BATCH_DRAFT]({ commit }, params) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Saving draft. Please wait...', {
        root: true,
      })
      await this.$saveOfferBatchDraft(params.data)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [SUBMIT_OFFER_BATCH]({ commit }, params) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Submitting offer response. Please wait...', {
        root: true,
      })
      const { id, status, data } = params
      await this.$submitOfferBatch(id, status, data)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },
}
