export const POSTHOG_EVENT_NAMES = {
  LOGIN: 'login',
  SIGNUP: 'signup',
  GOOGLE_SIGNIN: 'google-signin',

  OPEN_DASHBOARD: 'open-dashboard',

  BULK_UPLOAD_INVENTORY: 'bulk-upload-inventory',
  GENERATE_TEMPLATE: 'generate-template',
  TOOGLE_CUSTOM_VIEW: 'toogle-custom-view',

  GET_ALL_PRODUCT: 'get-all-product',

  DOWNLOAD_INSIGHT_PDF: 'download-insight-pdf',
  UPLOAD_OFFER_FORM: 'upload-offer-form',

  UPDATE_INVENTORY: 'update-inventory',
  CREATE_PRODUCT_CATALOG: 'create-product-catalog',
  ADD_MORE_PRODUCTS: 'create-product-catalog',
  CLICK_DOWNLOAD_PRODUCT_CATALOG_LINK: 'clicked-download-product-catalog',
  VIEW_DPRODUCT_CATALOG: 'view-product-catalog',
  CLICK_SHARE_PRODUCT_CATALOG_LINK: 'clicked-share-product-catalog',
}
