import { TIMEZONE } from '../utils/timezone'
import {
  SET_LOADING_TEXT,
  START_LOADING,
  STOP_LOADING,
  SAVE_SELLER_GENERAL,
  GET_SELLER_GENERAL,
  GET_SELLERS,
  SET_SELLERS,
  GET_TIMEZONE,
  SET_SELLER_PARAMS,
  SET_SELLER,
  GET_SELLER_LIST,
  SET_SELLER_DOWNLOAD_URL,
} from '../utils/constant'

export const state = () => ({
  seller: null,
  sellerInfo: null,
  timezone: [],
  sellers: [],
  sellerList: [],
  sellerParams: {},
  downloadUrl: '',
  industries: [
    { name: 'FMCG' },
    { name: 'Sports' },
    { name: 'Electronics' },
    { name: 'Clothing (Fashion)' },
    { name: 'Jewelry' },
    { name: 'Food & Beverage' },
    { name: 'Beauty & Cosmetics' },
    { name: 'Others' },
  ],
  typeList: [
    'Manufacturer - Principal',
    'Distributor - Sub Principal',
    'Retailer - Owner',
    'Retailer - Franchisee',
    'Wholesaler',
    'Online Marketplace - B2B',
    'Online Marketplace - B2C',
    'E-commerce - DTC Brand',
  ],
  estimates: [
    'Less than USD 10K',
    'USD 10K - USD 100K',
    'USD 100K - USD 500K',
    'USD 500K - USD 1M',
    'More than USD 1M',
  ],
  markets: [
    { name: 'Local Market' },
    { name: 'International Market' },
    { name: 'All' },
    { name: 'Website' },
    { name: 'Others' },
  ],
})

export const getters = {
  seller(state) {
    return state.seller
  },
  timezone(state) {
    // eslint-disable-next-line no-return-assign
    return state.timezone
  },
  sellerInfo(state) {
    return state.sellerInfo
  },
  sellers(state) {
    return state.sellers
  },
  sellerList(state) {
    return state.sellerList
  },
  sellerParams(state) {
    return state.sellerParams
  },
  industries(state) {
    return state.industries
  },
  typeList(state) {
    return state.typeList
  },
  estimates(state) {
    return state.estimates
  },
  markets(state) {
    return state.markets
  },
  downloadUrl(state) {
    return state.downloadUrl;
  }
}

export const mutations = {
  [GET_TIMEZONE](state, payload) {
    state.timezone = payload
  },
  [GET_SELLER_GENERAL](state, payload) {
    state.sellerInfo = payload
  },
  [SET_SELLERS](state, payload) {
    state.sellers = payload
  },
  [SET_SELLER](state, payload) {
    state.seller = payload
  },
  [SET_SELLER_PARAMS](state, payload) {
    state.sellerParams = payload
  },
  [SET_SELLER_DOWNLOAD_URL](state, payload) {
    state.downloadUrl = payload.url
  }
}

export const actions = {
  async [GET_SELLER_GENERAL]({ commit }, params) {
    try {
      const { data } = await this.$getSellerGeneral(params)
      commit(GET_SELLER_GENERAL, data.sellerInfo)
      commit(SET_SELLER, data)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  [GET_TIMEZONE]({ commit }) {
    try {
      const req = TIMEZONE
      commit(GET_TIMEZONE, req)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },
  async [SAVE_SELLER_GENERAL]({ commit }, params) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Saving update. Please wait...', {
        root: true,
      })
      const sellerId = params.sellerId
      delete params.sellerId
      const req = await this.$saveSellerGeneral(params, sellerId)
      commit(GET_SELLER_GENERAL, req)
      return req
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [GET_SELLERS]({ commit }) {
    try {
      const req = await this.$getSellers()
      commit(SET_SELLERS, req.data)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [GET_SELLER_LIST]({ commit }) {
    try {
      const req = await this.$getDownloadSeller();
      commit(SET_SELLER_DOWNLOAD_URL, req.data)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  }
}
