import urlencode from 'urlencode'

export default ($axios) => {
  return {
    getAllOrders: async (params) => {
      const res = await $axios.get(
        '/reports/order-history?page=' +
          params.page +
          '&size=' +
          params.size +
          '&order=' +
          params.order +
          '&orderby=' +
          params.orderby +
          '&search=' +
          urlencode(params.search) +
          '&field=' +
          params.field
      )
      return res
    },
  }
}
