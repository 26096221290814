import { GET_CURRENCY, CURRENCY_EXCHANGE_USD } from '../utils/constant'

export const state = () => ({
  currency: [],
  currencyExchangeUsd: {},
})

export const getters = {
  currency(state) {
    return state.currency
  },
  currencyExchangeUsd(state) {
    return state.currencyExchangeUsd
  },
}

export const mutations = {
  [GET_CURRENCY](state, payload) {
    state.currency = payload
  },
  [CURRENCY_EXCHANGE_USD](state, payload) {
    state.currencyExchangeUsd = payload
  },
}

export const actions = {
  async [GET_CURRENCY]({ commit }) {
    try {
      const { data } = await this.$getCurrency()
      const currencyExchangeUsd = {}
      const currency = data.map((curr) => {
        currencyExchangeUsd[curr.source] = curr.rate
        return curr.source
      })
      commit(GET_CURRENCY, currency)
      commit(CURRENCY_EXCHANGE_USD, currencyExchangeUsd)
    } catch (error) {}
  },
}
