export default async function ({
  redirect,
  $checkOrCreateInitialSellerAccount,
  $checkSellerOnboard,
}) {
  try {
    // NOTE: This middleware ware is meant to check whether seller exist or not and create one
    // it should happen after things being mounted rather than as middleware
    // WARN: don't use middleware
    // NOTE: this implementation already moved to `mounted()` hooks on `onboarding` page
    await $checkOrCreateInitialSellerAccount()
    const { data } = await $checkSellerOnboard()
    // this check should only check if the seller exist or not
    if (data.sellerInfo) {
      const user = await $getUser()

      if (user.pollenVerified === 'APPROVED') {
        return redirect('/dashboard')
      } else {
        return redirect('/verification')
      }
    }
  } catch (error) {
    // redirect to login if unauthorized
    // NOTE: this should not even happen because this should be handled by the auth middleware
    this.$sentry.captureException(error)
    return redirect('/login')
  }
}
