import qs from 'querystring'
/**
 * @param {import('axios').AxiosInstance} $axios
 */
export default ($axios) => {
  return {
    buyer: () => ({
      async fetch(params) {
        const { data } = await $axios.get(`/buyers?${qs.stringify(params)}`)
        return data
      },
      async get(id) {
        const { data } = await $axios.get(`/buyers/${id}`)
        return data
      },
      async linkPollenAccount(id) {
        const { data } = await $axios.put(`/buyers/${id}/init-account`)
        return data
      },
      async freshsaleSync() {
        const { data } = await $axios.put(`/buyers/freshsale-sync`)
        return data
      },
      async regenerateResetPasswordLink(id) {
        const { data } = await $axios.put(
          `/buyers/${id}/regenerate-reset-password-link`
        )
        return data
      },
    }),
  }
}
