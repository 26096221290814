export const state = () => ({
  imports: [],
  importFileIdUpload: null,
  importTemplate: [
    {
      label: 'general',
      link: 'https://s3.ap-southeast-1.amazonaws.com/assets.mypollenstore.com/templates/General+Template.xlsx',
    },
    {
      label: 'variant',
      link: 'https://s3.ap-southeast-1.amazonaws.com/assets.mypollenstore.com/templates/Variant+Template.xlsx',
    },
  ],
})

export const mutations = {
  addImport(state, importItem) {
    state.imports.push(importItem)
  },
  clearAll(state) {
    state.imports = []
  },
  removeImport(state, importId) {
    state.imports = state.imports.filter(
      (importItem) => importItem.id !== importId
    )
  },
  setState(state, item) {
    state[item.field] = item.value
  },
  clearState(state, item) {
    state[item] = null
  },
}

export const actions = {
  addImport(context, importItem) {
    context.commit(importItem)
  },
}

export const getters = {
  getImportTemplate: (state) => (label) => {
    return state.importTemplate.find((data) => data.label === label)
  },
  getImports: (state) => {
    return state.imports
  },
  getState: (state) => (item) => {
    return state[item]
  },
}
