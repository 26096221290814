import {
  UPLOAD_REPORT_DIALOG,
  SAVE_REPORT,
  SAVE_REPORT_COUNT,
  GET_REPORTS,
  START_LOADING,
  STOP_LOADING,
  SET_LOADING_TEXT,
  GET_EMBED_URL,
} from '../utils/constant'
export const state = () => ({
  reportFile: [
    {
      month: 'october',
      link: 'https://s3.ap-southeast-1.amazonaws.com/assets.mypollenstore.com/Inventory+Report+12-Oct-2022-114215.pdf',
    },
  ],
  reportUploadDialog: false,
  reportSaved: 0,
  reportHeader: [
    { text: 'No', value: 'id' },
    { text: 'File Name', value: 'name' },
    { text: 'Date Submitted', value: 'createdAt' },
    { text: 'Action', value: 'action' },
  ],
  reports: [],
  embedUrl: null,
})

export const getters = {
  reportFile(state) {
    return state.reportFile
  },
  reportUploadDialog(state) {
    return state.reportUploadDialog
  },
  reportSaved(state) {
    return state.reportSaved
  },
  reportHeader(state) {
    return state.reportHeader
  },
  reports(state) {
    return state.reports
  },
  embedUrl(state) {
    return state.embedUrl
  },
}

export const mutations = {
  [UPLOAD_REPORT_DIALOG](state, payload) {
    state.reportUploadDialog = payload
  },
  [SAVE_REPORT](state, payload) {
    state.reportUploadDialog = payload
  },
  [SAVE_REPORT_COUNT](state, payload) {
    state.reportSaved = payload
  },
  [GET_REPORTS](state, payload) {
    state.reports = payload
  },
  [GET_EMBED_URL](state, payload) {
    state.embedUrl = payload
  },
}

export const actions = {
  async [SAVE_REPORT]({ commit }, params) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Saving report. Please wait...', {
        root: true,
      })
      const req = await this.$saveReport(params)
      commit(SAVE_REPORT_COUNT, req)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },
  async [GET_REPORTS]({ commit }) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Fetching report...', {
        root: true,
      })
      const req = await this.$getReports()
      commit(GET_REPORTS, req.data)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },
  async [GET_EMBED_URL]({ commit }) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Generating embed url...', {
        root: true,
      })
      const req = await this.$getEmbedUrl()
      commit(GET_EMBED_URL, req.data)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },
}
