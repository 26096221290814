import urlencode from 'urlencode'
import { POSTHOG_EVENT_NAMES } from '~/utils/posthog-constant'

/**
 * @param {import('@nuxtjs/axios').NuxtAxiosInstance} $axios
 */
export default ($axios, $posthog) => {
  return {
    saveProductCatalog: async (params) => {
      $posthog.capture(POSTHOG_EVENT_NAMES.CREATE_PRODUCT_CATALOG, params)
      const { data } = await $axios.post('/catalogs', params)
      return data
    },
    getProductCatalogs: async (params) => {
      let filter = ''
      if (params.filter.length > 0) {
        params.filter.forEach(function (item) {
          if (item.value) {
            item.field.op = item.value === '1' ? 'gte' : item.field.op
            filter +=
              '&filter=' +
              item.field.type +
              ':' +
              item.field.name +
              ':' +
              item.field.op +
              ':' +
              urlencode(item.value)

            if (item.maxvalue) {
              filter +=
                '&filter=' +
                item.field.type +
                ':' +
                item.field.name +
                ':lte:' +
                urlencode(item.maxvalue)
            }
          }
        })
      }
      const searchfield = params.field.value || params.field

      const res = await $axios.get(
        '/v2/catalogs?page=' +
          params.page +
          '&size=' +
          params.size +
          '&order=' +
          params.order +
          '&orderby=' +
          params.orderby +
          '&search=' +
          urlencode(params.search) +
          '&field=' +
          searchfield +
          filter
      )
      return res
    },
    getCatalog: async (params) => {
      const { data } = await $axios.get('/catalogs/' + params)
      return data
    },

    deleteCatalog: async (param) => {
      return await $axios.delete('/catalogs/' + param)
    },

    setCatalogLive: async (params) => {
      const { id, live } = params;
      const { data } = await $axios.put(`/catalogs/${id}/live`, { live })
      return data;

    }
  }
}
