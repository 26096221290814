import {
  START_LOADING,
  SET_LOADING_TEXT,
  STOP_LOADING,
  SET_ALERT_NOTIFICATION,
  SHOW_ALERT_NOTIFICATION,
  SET_ALERT_NOTIFICATION_COLOR,
  SET_ALERT_NOTIFICATION_CODE,
  SHOW_IMPERSONATION,
  SHOW_ALERT,
  ALERT_MESSAGE,
} from '../utils/constant'

export const state = () => ({
  appLoading: false,
  appLoadingText: 'loading...',
  alertNotificationText: null,
  showAlertNotification: false,
  alertNotificationColor: 'red',
  showImpersonationAlert: false,
  alertNotificationCode: '',
  showAlert: false,
  alertMessage: '',
})

export const mutations = {
  [START_LOADING](state) {
    state.appLoading = true
  },

  [STOP_LOADING](state) {
    state.appLoading = false
  },

  [SET_LOADING_TEXT](state, payload) {
    state.appLoadingText = payload
  },

  [SET_ALERT_NOTIFICATION](state, payload) {
    state.alertNotificationText = payload
  },

  [SHOW_ALERT_NOTIFICATION](state, payload) {
    state.showAlertNotification = payload
  },
  [SET_ALERT_NOTIFICATION_COLOR](state, payload) {
    state.alertNotificationColor = payload
  },

  [SHOW_IMPERSONATION](state, payload) {
    state.showImpersonationAlert = payload
  },

  [SET_ALERT_NOTIFICATION_CODE](state, payload) {
    state.alertNotificationCode = payload
  },

  [SHOW_ALERT](state, payload) {
    state.showAlert = payload
  },

  [ALERT_MESSAGE](state, payload) {
    state.alertMessage = payload
  },
}

export const getters = {
  appLoading(state) {
    return state.appLoading
  },
  appLoadingText(state) {
    return state.appLoadingText
  },
  alertNotificationText(state) {
    return state.alertNotificationText
  },
  showAlertNotification(state) {
    return state.showAlertNotification
  },
  showImpersonationAlert(state) {
    return state.showImpersonationAlert
  },
  alertNotificationCode(state) {
    return state.alertNotificationCode
  },
  showAlert(state) {
    return state.showAlert
  },
  alertMessage(state) {
    return state.alertMessage
  },
  alertNotificationColor(state) {
    return state.alertNotificationColor
  },
}
