export default ($axios) => {
  return {
    getRoles: async () => {
      const { data } = await $axios.get('/acl/roles')
      return data
    },
    setImpersonation: async (sellerId) => {
      const { data } = await $axios.post('/acl/impersonate', { sellerId })
      return data
    },
    updateImpersonation: async (sellerId) => {
      const { data } = await $axios.post('/acl/impersonate/update', {
        sellerId,
      })
      return data
    },
    cancelImpersonation: async () => {
      const { data } = await $axios.post('/acl/impersonate/cancel')
      return data
    },
  }
}
