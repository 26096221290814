import * as qs from 'querystring'

export default ($axios) => {
  return {
    saveSellerOnboard: async (body) => {
      return await $axios.post('/sellers/info', body)
    },
    checkSellerOnboard: async () => {
      return await $axios.get('/sellers/info')
    },
    // getSellers: async () => {
    //   return await $axios.get('/sellers')
    // },
    getSellers: async (params) => {
      const res = await $axios.get(
        `/sellers?${qs.stringify({
          ...params,
        })}`
      )
      return res
    },
    checkOrCreateInitialSellerAccount: async () => {
      return await $axios.post('/seller_accounts')
    },
    saveSellerGeneral: async (body, sellerId) => {
      return await $axios.put('/sellers/general/' + sellerId, body)
    },
    getSellerGeneral: async (body) => {
      return await $axios.get('/sellers/general/' + body)
    },
    getDownloadSeller: async () => {
      return await $axios.get('/sellers/download-list')
    }
  }
}
