export const reverseObject = (obj) => {
  const ret = {}
  Object.keys(obj).forEach((key) => {
    ret[obj[key]] = key
  })
  return ret
}

export const sortArray = (arrObjs, param) => {
  return arrObjs.sort((a, b) => {
    let val
    if (typeof a[param] === 'string') {
      if (a[param] < b[param]) {
        val = -1
      }

      if (a[param] > b[param]) {
        val = 1
      }
    } else if (typeof a[param] === 'boolean') {
      b[param] = b[param] ? b[param] : false
      a[param] = a[param] ? a[param] : false
      val = Number(b[param] - Number(a[param]))
    } else {
      val = a[param] - b[param]
    }
    return val
  })
}

// remove properties in an object
export const removePropInObject = (obj, props = []) => {
  props.forEach((prop) => {
    delete obj[prop]
  })

  return obj
}

export const selectOnlyPropInObject = (obj, props = []) => {
  const newObject = {}
  for (const [key, value] of Object.entries(obj)) {
    if (props.includes(key)) {
      newObject[key] = value
    }
  }
  return newObject
}
