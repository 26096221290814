import awaitToError from '~/utils/awaitToError'
import { START_LOADING, STOP_LOADING } from '~/utils/constant'

export const state = () => ({
  buyers: {},
  buyerHeaders: [
    { text: 'Firstname', value: 'firstName' },
    { text: 'Lastname', value: 'lastName' },
    { text: 'email', value: 'email' },
    { text: 'Work Number', value: 'workNumber' },
    { text: 'Address', value: 'address' },
    { text: 'City', value: 'city' },
    { text: 'State', value: 'state' },
    { text: 'Zip Code', value: 'zipcode' },
    { text: 'Country', value: 'country' },
    { text: 'Pollen Account', value: 'user' },
  ],
  actionItems: [
    {
      text: 'Invite',
      value: 'inviteBuyer',
      icon: 'mdi-invite',
      disabled: false,
    },
    {
      text: 'Copy Change Password Link',
      value: 'changePasswordLink',
      icon: 'mdi-invite',
      disabled: false,
    },
  ],
})

export const getters = {
  buyerHeaders($state) {
    return $state.buyerHeaders
  },
  buyerList($state) {
    return $state.buyers
  },
  actionItems($state) {
    return $state.actionItems
  },
}

export const mutations = {
  buyers(state, payload) {
    state.buyers = payload
  },
}

export const actions = {
  async getBuyer({ commit }, params) {
    commit(START_LOADING, true, { root: true })
    const [err, data] = await awaitToError(this.$buyer().fetch(params))
    !err && commit('buyers', data)
    commit(STOP_LOADING, false, { root: true })
  },
}
