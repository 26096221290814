import urlencode from 'urlencode'
import awaitToError from '~/utils/awaitToError'

export default ($axios) => {
  return {
    getUser: async () => {
      const { data } = await $axios.get('/users/me')
      return data
    },
    getUsers: async (params) => {
      const { data } = await $axios.get(
        '/users?page=' +
          params.page +
          '&size=' +
          params.size +
          '&order=' +
          params.order +
          '&orderby=' +
          params.orderby +
          '&search=' +
          urlencode(params.search) +
          '&field=' +
          params.field
      )
      return data
    },
    saveUser: async (body) => {
      return await $axios.post('/users/auth0', body)
    },
    approveUser: async (userId) => {
      return await $axios.put('/users/approve/' + userId)
    },
    rejectUser: async (userId) => {
      return await $axios.put('/users/reject/' + userId)
    },
    mergeUser: async (userId, sellerId) => {
      return await $axios.put('/users/merge/' + userId, { sellerId })
    },
    updateProfile: async (params) => {
      const [, result] = await awaitToError(
        $axios.patch('/users/profile', params)
      )
      return result
    },
  }
}
