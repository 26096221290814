//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  GET_SELLERS,
  SET_IMPERSONATION,
  UPDATE_IMPERSONATION,
  CANCEL_IMPERSONATION,
  GET_USER,
  SET_USER,
  SHOW_IMPERSONATION,
  SET_ALERT_NOTIFICATION,
  SHOW_ALERT_NOTIFICATION,
} from '../utils/constant'
export default {
  name: 'ToolBar',
  props: {
    title: {
      type: String,
      default: 'Liquidation Management System v2',
    },
  },
  data: () => ({
    clipped: true,
    drawer: false,
    fixed: true,
    loading: false,
    selection: 1,
    miniVariant: true,
    right: true,
    items: [{ title: 'Log out', link: '/logout' }],
    sellerImpersonate: '',
    sellerCompanyName: '',
    notification: { sellerPending: 0 },
    n: 0,
    zIndex: 999,
  }),
  computed: {
    ...mapGetters('seller', ['sellers']),
    ...mapGetters('users', ['isSuperAdmin', 'user']),
    ...mapGetters('acl', ['impersonate']),
    ...mapGetters(['alertNotificationCode']),
    sellerName() {
      if (this.user.impersonation && this.user.impersonation.seller) {
        return this.user.impersonation.seller.companyName
      }

      return ''
    },
    isImpersonating() {
      if (
        this.user.impersonation.active === true &&
        this.user.impersonation.seller
      ) {
        return true
      }

      return false
    },
  },
  watch: {
    isImpersonating() {
      if (this.isImpersonating) {
        this.setImpersonationMutation(this.user.impersonation)
      }
    },
  },
  async mounted() {
    await this[GET_SELLERS]()
    this.resetUser()
    this.getNotification()
  },
  methods: {
    ...mapActions('seller', [GET_SELLERS]),
    ...mapActions('users', [GET_USER]),
    ...mapActions('acl', [
      SET_IMPERSONATION,
      UPDATE_IMPERSONATION,
      CANCEL_IMPERSONATION,
    ]),
    ...mapMutations('users', [SET_USER]),
    ...mapMutations('acl', {
      setImpersonationMutation: SET_IMPERSONATION,
    }),
    ...mapMutations([
      SHOW_IMPERSONATION,
      SET_ALERT_NOTIFICATION,
      SHOW_ALERT_NOTIFICATION,
    ]),
    async setImpersonate(sellerId) {
      if (sellerId) {
        await this[SET_IMPERSONATION](sellerId)

        if (this.alertNotificationCode !== 'IMPERSONATE_EXISTS') {
          window.location.href = window.location.pathname
        }

        setTimeout(() => {
          this[SHOW_ALERT_NOTIFICATION](false)
        }, 3000)
      }
    },
    async cancelImpersonationAsSeller() {
      await this[CANCEL_IMPERSONATION]()
      this.resetUser()
      window.location.href = window.location.pathname
    },
    async resetUser() {
      try {
        const user = await this.$getUser()
        const currentUserInfo = {
          ...user,
        }
        localStorage.setItem('sellerInfo', JSON.stringify(currentUserInfo))
        this[SET_USER](currentUserInfo)
        this.sellerCompanyName =
          currentUserInfo.sellerAccounts.length >= 1
            ? currentUserInfo.sellerAccounts[0].seller.companyName
            : ''
      } catch (err) {}
    },
    getNotification() {
      // Set notification
      const scope = this
      // Request Sellers Pending
      scope.getSellersPending(scope)
      setInterval(function () {
        scope.getSellersPending(scope)
      }, 300000)
    },
    async getSellersPending(scope) {
      const req = await scope.$getSellers({
        page: 1,
        size: 999,
        order: 'asc',
        orderby: 'companyName',
        search: '',
        field: null,
      })
      if (req) {
        scope.notification.sellerPending = req.data.pendingRequest
      }
    },
    goToSellers() {
      this.$router.push({
        name: 'setting-sellers',
      })
    },
    formatDate(param) {
      return moment(param).format('MM/DD')
    },
  },
}
