export default ($axios) => {
  return {
    getAllLots: async (params) => {
      const res = await $axios.get(
        '/lots?page=' +
          params.page +
          '&size=' +
          params.size +
          '&order=' +
          params.order +
          '&orderby=' +
          params.orderby
      )
      return res
    },
    getLot: async (params) => {
      const res = await $axios.get('/lots/' + params)
      return res
    },
    saveLot: async (params) => {
      const { data } = await $axios.post('/lots', params)
      return data
    },
    saveLotImage: async (params) => {
      const { data } = await $axios.post('/lots/images', params)
      return data
    },
    deleteLotImage: async (params) => {
      const { data } = await $axios.delete('/lots/images/' + params)
      return data
    },
  }
}
