//
//
//
//
//
//
//
//
//
//

import PollenError from '~/components/common/PollenError.vue'
import PollenFeedback from '~/components/feedback/Feedback.vue'
export default {
  name: 'DefaultLayout',
  components: {
    'pollen-error': PollenError,
    'pollen-feedback': PollenFeedback,
  },
  data() {
    return {
      title: 'Liquidation Management System (LMS) v2',
    }
  },
}
