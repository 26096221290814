import { GET_ORDER_LIST, START_LOADING, STOP_LOADING } from '~/utils/constant'

export const state = () => ({
  order: null,
  orderImports: [],
  orderList: [],
  orderTemplate: null,
  orderErrorSummary: [],
  orderSavedImports: 0,
  orderSelected: [],
  orderSelectedDialog: false,
  orderListDialog: false,
  orderSearch: [
    { text: 'Offer ID', value: 'id' },
    { text: 'SKU Number', value: 'skuNumber' },
  ],
})

export const getters = {
  orderSearch(state) {
    return state.orderSearch
  },
  orderList(state) {
    return state.orderList
  },
}

export const mutations = {
  [GET_ORDER_LIST](state, payload) {
    state.orderList = payload
  },
}

export const actions = {
  async [GET_ORDER_LIST]({ commit }) {
    try {
      commit(START_LOADING, true, { root: true })
      const data = await this.$getAllOrders()
      commit(GET_ORDER_LIST, data)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },
}
