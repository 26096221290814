export default ($axios) => {
  return {
    parseManifestBatch: async (params) => {
      const { data } = await $axios.post('/batches/parse-manifest-file', params)
      return data
    },
    validateRequiredBatch: async (params) => {
      const { data } = await $axios.post('/batches/validate-required', params)
      return data
    },
    validateDuplicateBatch: async (params) => {
      const { data } = await $axios.post('/batches/validate-duplicate', params)

      return data
    },
    downloadLogsBatch: async (products, template, logType) => {
      const { data } = await $axios.post(
        `/batches/download-log?include=${logType}`,
        {
          data: products,
          template,
        }
      )

      return data
    },
    bulkInsertBatch: async (data, template, isUpdate) => {
      const { data: reqData } = await $axios.post(`/batches/bulk-insert`, {
        data,
        template,
        isUpdate,
      })
      return reqData
    },
    saveBatchImage: async (id, fileId) => {
      const { data: reqData } = await $axios.post(`/batches/${id}/images`, {
        file: fileId,
      })
      return reqData
    },

    deleteBatchImage: async (id) => {
      const { data } = await $axios.delete(`/batches/${id}/images`)
      return data
    },
    warehouseLocationList: async (params) => {
      const { data } = await $axios.get(`/batches/warehouse-location`, params)
      return data
    }
  }
}
