export const SENTRY_EVENT_NAMES = {
  GET_PRIORITY: 'get-priority',
  GET_SLOB_INDEX: 'get-slob-index',

  GET_BATCH_INVENTORY: 'get-batch-inventory',
  GET_PRODUCT_INVENTORY: 'get-product-inventory',
  GET_CATALOG_INVENTORY: 'get-catalog-inventory',
  GET_OFFERS: 'get-offers',
  GET_REPORTS: 'get-reports',

  GET_BRANDS: 'get-brands',
  GET_CATEGORY: 'get-category',
  GET_SELLERS: 'get-sellers',
  GET_USERS: 'get-users',
  GET_BUYERS: 'get-buyers',
  GET_ROLES: 'get-roles',
}
