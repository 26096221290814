import {
  START_LOADING,
  STOP_LOADING,
  SET_LOADING_TEXT,
  SAVE_PRODUCT_CATALOG_COUNT,
  SAVE_PRODUCT_CATALOG,
  GET_ALL_PRODUCT_CATALOG,
  GET_CATALOG_URL,
  GET_CATALOG,
  DELETE_PRODUCT_CATALOG,
  SET_CATALOG_PARAMS,
  SET_CATALOG_LIVE,
} from '../utils/constant'

export const state = () => ({
  productCatalog: [],
  productCatalogSaved: null,
  createCatalogHeaders: [
    { text: 'Product Name', value: 'productName' },
    { text: 'SKU Number', value: 'skuNumber' },
    { text: 'Allocated QTY', value: 'allocatedQuantity' },
    // { text: 'SKU Name', value: 'skuName' },
    { text: 'Expiry date', value: 'expiryDate' },
    { text: 'Package type', value: 'packageType' },
    { text: 'Items per package', value: 'itemsPerPackageType' },
    { text: 'Pack size(number)', value: 'packSizeNum' },
    { text: 'Actions', value: 'actions', sortable: false },
  ],
  catalogSearch: [
    { text: 'Catalog Name', value: 'name' },
    { text: 'Description', value: 'description' },
    { text: 'Customer Name', value: 'buyers' },
  ],
  catalogFilter: [
    // { name: 'createdAt', label: 'Created At', type: 'date', op: 'gte' },
    // {
    //   name: 'createdBy',
    //   label: 'Created By',
    //   type: 'string',
    //   op: 'eq',
    // },
    {
      name: 'live',
      label: 'Live',
      type: 'boolean',
      op: 'eq',
    },
  ],
  catalogLiveStatus: [
    { text: 'Yes', value: 'true' },
    { text: 'No', value: 'false' },
  ],
  catalogUrl: '',
  catalogDetails: '',
  actionItems: [
    // {
    //   text: 'Preview',
    //   value: 'detailsCatalog',
    //   icon: 'mdi-eye',
    //   disabled: false,
    // },
    {
      text: 'Download',
      value: 'exportCatalog',
      icon: 'mdi-cloud-download-outline',
      disabled: false,
    },
    {
      text: 'Copy Link',
      value: 'copyCatalog',
      icon: 'mdi-content-copy',
      disabled: false,
    },
    {
      text: 'Copy Market Link',
      value: 'copyMarketLink',
      icon: 'mdi-content-copy',
      disabled: false,
    },
    {
      text: 'Delete',
      value: 'deleteCatalog',
      icon: 'mdi-delete',
      disabled: false,
    },
    {
      text: 'View Downloads',
      value: 'viewDownload',
      icon: 'mdi-chart-bar',
      disabled: false,
    },
  ],
  catalogParams: {},
})

export const getters = {
  createCatalogHeaders(state) {
    return state.createCatalogHeaders
  },
  catalogFilter(state) {
    return state.catalogFilter
  },
  productCatalog(state) {
    return state.productCatalog
  },
  catalogSearch(state) {
    return state.catalogSearch
  },
  catalogLiveStatus(state) {
    return state.catalogLiveStatus
  },
  catalogUrl(state) {
    return state.catalogUrl
  },
  catalogDetails(state) {
    return state.catalogDetails
  },
  actionItems(state) {
    return state.actionItems
  },
  catalogParams(state) {
    return state.catalogParams
  },
}
export const mutations = {
  [SAVE_PRODUCT_CATALOG_COUNT](state, payload) {
    state.productCatalogSaved = payload
  },
  [GET_ALL_PRODUCT_CATALOG](state, payload) {
    state.productCatalog = payload
  },
  [GET_CATALOG_URL](state, payload) {
    state.catalogUrl = payload
  },
  [GET_CATALOG](state, payload) {
    state.catalogDetails = payload
  },
  [SET_CATALOG_PARAMS](state, payload) {
    state.catalogParams = payload
  },
}

export const actions = {
  async [GET_CATALOG]({ commit }, params) {
    try {
      commit(START_LOADING, true, { root: true })
      const data = await this.$getCatalog(params)
      commit(GET_CATALOG, data)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [GET_ALL_PRODUCT_CATALOG]({ commit }) {
    try {
      commit(START_LOADING, true, { root: true })
      const data = await this.$getAllProductCatalog()
      commit(GET_ALL_PRODUCT_CATALOG, data)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [SAVE_PRODUCT_CATALOG]({ commit }, params) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Saving Product Catalog. Please wait...', {
        root: true,
      })
      const req = await this.$saveProductCatalog(params.catalogDetails)
      commit(SAVE_PRODUCT_CATALOG_COUNT, req)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [DELETE_PRODUCT_CATALOG]({ commit }, params) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Deleting Product Catalog. Please wait...', {
        root: true,
      })
      const { id } = params
      await this.$deleteCatalog(id)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [SET_CATALOG_LIVE]({ commit }, params) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Set Catalog Live. Please wait...', {
        root: true,
      })
      await this.$setCatalogLive(params)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },
}
