import {
  SET_BATCH_IMPORTS,
  GET_BATCH_INVENTORY,
  START_LOADING,
  STOP_LOADING,
  SET_LOADING_TEXT,
  PARSE_VALIDATE_BATCH,
  SET_BATCH_HEADERS,
  SET_BATCH_TEMPLATE,
  SET_BATCH_ERROR_SUMMARY,
  SET_BATCH_DUPLICATE_SUMMARY,
  DOWNLOAD_LOGS_BATCH,
  SET_DOWNLOAD_LOG_URL_BATCH,
  SORT_ARRAY_BATCH,
  SAVE_BATCH_IMPORTS,
  SAVE_BATCH_IMPORTS_COUNT,
  COMPUTE_SELECTED_BATCH,
  CALCULATE_SELECTED_BATCH,
  REPLACE_BATCH_IMPORTS,
  SAVE_BATCH_IMAGE,
  GET_BATCH_WAREHOUSE_LOCATION,
  BATCHES_IMAGE_UPLOAD_DIALOG,
  DELETE_BATCH_IMAGE
} from '../utils/constant'

import { sortArray } from '../utils/helpers'

export const state = () => ({
  batch: null,
  batchImports: [],
  batchInventory: [],
  batchHeaders: [],
  batchErrorSummary: [],
  batchDuplicateSummary: [],
  batchDownloadLogUrl: '',
  batchSavedImports: 0,
  batchTemplate: null,
  batchImageUploadDialog: false,
  batchImages: [],
  batchWarehouseLocation: [],
})

export const getters = {
  batchTemplate(state) {
    return state.batchTemplate
  },
  batch(state) {
    return state.batch
  },
  batchImports(state) {
    return state.batchImports
  },
  batchInventory(state) {
    return state.batchInventory
  },
  batchHeaders(state) {
    return state.batchHeaders
  },
  batchErrorSummary(state) {
    return state.batchErrorSummary
  },
  batchDuplicateSummary(state) {
    return state.batchDuplicateSummary
  },
  batchDownloadLogUrl(state) {
    return state.batchDownloadLogUrl
  },
  batchSavedImports(state) {
    return state.batchSavedImports
  },
  batchImageUploadDialog(state) {
    return state.batchImageUploadDialog
  },
  batchImage(state) {
    return state.batchImage
  },
  batchWarehouseLocation(state) {
    return state.batchWarehouseLocation
  }
}

export const mutations = {
  [SET_BATCH_IMPORTS](state, payload) {
    const data = payload.map((imProduct) => {
      let errorObjs = {}
      if (imProduct.errors) {
        imProduct.errors.forEach((eP) => {
          errorObjs[eP.field] = eP.message
        })
      } else {
        errorObjs = null
      }
      return {
        ...imProduct,
        errors: errorObjs,
      }
    })
    state.batchImports = data
  },
  [GET_BATCH_INVENTORY](state, payload) {
    state.batchInventory = payload
  },
  [SORT_ARRAY_BATCH](state, payload) {
    state.batchImports = sortArray(payload.data, payload.key)
  },
  [SET_BATCH_TEMPLATE](state, payload) {
    state.batchTemplate = payload
  },
  [SET_BATCH_HEADERS](state, payload) {
    state.batchHeaders = payload
  },
  [SET_BATCH_ERROR_SUMMARY](state, payload) {
    state.batchErrorSummary = payload
  },
  [SET_BATCH_DUPLICATE_SUMMARY](state, payload) {
    state.batchDuplicateSummary = payload
  },
  [BATCHES_IMAGE_UPLOAD_DIALOG](state, payload) {
    state.batchImageUploadDialog = payload
  },
  [GET_BATCH_WAREHOUSE_LOCATION](state, payload) {
    state.batchWarehouseLocation = payload
  },
}

export const actions = {
  async [PARSE_VALIDATE_BATCH]({ commit }, fileId) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Parsing manifest file...', { root: true })
      const parsedData = await this.$parseManifestBatch({ fileId })
      commit(SET_BATCH_TEMPLATE, parsedData.template)
      commit(SET_LOADING_TEXT, 'Validating required fields...', { root: true })
      const validateRequired = await this.$validateRequiredBatch(parsedData)
      commit(SET_BATCH_ERROR_SUMMARY, validateRequired.error_summary || [])
      commit(SET_BATCH_HEADERS, validateRequired.headers || [])
      commit(SET_LOADING_TEXT, 'Validating duplicate in manifest...', {
        root: true,
      })
      const validateDuplicate = await this.$validateDuplicateBatch(
        validateRequired
      )
      commit(
        SET_BATCH_DUPLICATE_SUMMARY,
        validateDuplicate.error_duplicates || []
      )
      commit(SET_BATCH_IMPORTS, validateDuplicate.data)
    } catch (error) {
      return {
        error: true,
        message: error.message,
      }
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [DOWNLOAD_LOGS_BATCH]({ commit }, params) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Exporting logs. Please wait...', { root: true })
      const req = await this.$downloadLogsBatch(
        params.data,
        params.template,
        params.logType
      )
      commit(SET_DOWNLOAD_LOG_URL_BATCH, req.url)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [SAVE_BATCH_IMPORTS]({ commit }, params) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Saving Imported Data. Please wait...', {
        root: true,
      })
      const req = await this.$bulkInsertBatch(params.data, params.template, params.isUpdate)
      commit(SAVE_BATCH_IMPORTS_COUNT, req.batchSaved)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  [REPLACE_BATCH_IMPORTS](state, payload) {
    state.batchSavedImports = state.batchSavedImports.splice(
      payload.findIndex,
      1,
      payload.newValue
    )
  },
  async [COMPUTE_SELECTED_BATCH]({ commit }, productIds) {
    try {
      const data = await this.$calculateSelectedProducts(productIds)
      commit(CALCULATE_SELECTED_BATCH, data)
    } catch (error) {
    } finally {
      // commit(STOP_LOADING, false, { root: true })
    }
  },

  async [SAVE_BATCH_IMAGE]({ commit }, params) {
    try {
      commit(START_LOADING, true, { root: true })
      const req = await this.$saveBatchImage(params.id, params.fileId)
      commit(GET_BATCH_INVENTORY, req)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },
  async [DELETE_BATCH_IMAGE]({ commit }, params) {
    try {
      const req = await this.$deleteBatchImage(params.id)
      commit(DELETE_BATCH_IMAGE, req)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },
  async [GET_BATCH_WAREHOUSE_LOCATION]({ commit }, params) {
    try {
      const req = await this.$warehouseLocationList(params)
      commit(GET_BATCH_WAREHOUSE_LOCATION, req)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  }
}
