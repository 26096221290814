//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapGetters } from 'vuex'
import {
  SHOW_ALERT_NOTIFICATION,
  SET_ALERT_NOTIFICATION,
  VALIDATION_MESSAGE,
} from '~/utils/constant'

export default {
  name: 'PollenFeedback',
  data: () => ({
    isLoading: false,
    isSuccessFeedback: false,
    dialog: false,
    valid: true,
    mandatoryRulesText: [(v) => !!v || 'This field is required'],
    form: {
      title: '',
      message: '',
    },
  }),
  computed: {
    ...mapGetters('users', ['user']),
  },
  mounted() {
    this.isSuccessFeedback = false
    this.openAlertNotification(false)
    this.setAlertNoficationText('')
  },
  methods: {
    ...mapMutations({
      openAlertNotification: SHOW_ALERT_NOTIFICATION,
      setAlertNoficationText: SET_ALERT_NOTIFICATION,
    }),
    resetForm() {
      this.form = {
        message: '',
      }
    },
    async saveFeedback() {
      try {
        const { firstName = '', lastName = '', email = '' } = this.user
        this.isLoading = true
        if (this.$refs.formFeedback.validate()) {
          const eventId = await this.$sentry.captureMessage('User Feedback')
          const userFeedback = {
            event_id: eventId,
            name: `${firstName} ${lastName}`,
            email,
            comments: this.form.message,
          }
          await this.$sentry.captureUserFeedback(userFeedback)
          this.isSuccessFeedback = true
          this.isLoading = false
        } else {
          this.isLoading = false
          this.setAlertNoficationText(VALIDATION_MESSAGE.REQUIRED_FIELDS)
          this.openAlertNotification(true)
          return
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    },
    closeFunc() {
      this.resetForm()
      this.dialog = false
      this.isSuccessFeedback = false
    },
  },
}
