//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from 'vuex'
import {
  SHOW_ALERT_NOTIFICATION,
  SET_ALERT_NOTIFICATION,
  SET_ALERT_NOTIFICATION_COLOR,
} from '../../utils/constant'
export default {
  name: 'PollenError',
  props: {
    iconval: {
      type: String,
      default: 'mdi-alert',
    },
  },
  data() {
    return {
      defaultColor: 'red',
    }
  },
  computed: {
    ...mapGetters([
      'showAlertNotification',
      'alertNotificationText',
      'alertNotificationColor',
    ]),
    isAlertShow: {
      get() {
        return this.showAlertNotification
      },
      set(newValue) {
        this.openAlertNotification(newValue)
      },
    },
  },
  watch: {
    isAlertShow() {
      if (this.isAlertShow) {
        this.openAlertNotification(this.showAlertNotification)
      }
    },
  },
  mounted() {},
  methods: {
    ...mapMutations({
      openAlertNotification: SHOW_ALERT_NOTIFICATION,
      setAlertNoficationText: SET_ALERT_NOTIFICATION,
      setAlertNoficationColor: SET_ALERT_NOTIFICATION_COLOR,
    }),
  },
}
