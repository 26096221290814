import {
  GET_USER,
  SET_USER,
  GET_USERS,
  SET_USERS,
  ROLES,
  ADD_USER_DIALOG,
  START_LOADING,
  STOP_LOADING,
} from '../utils/constant'
export const state = () => ({
  user: {
    impersonation: {
      active: false,
      seller: {
        name: '',
      },
    },
  },
  users: [],
  superadmin: [
    'dashboard',
    'product',
    'productIventory',
    'productsIventory',
    'productCatalog',
    'productImport',
    'productCategory',
    'productBrand',
    'offerInventory',
    'offers',
    'offerForm',
    'orders',
    'orderHistory',
    'uploadOfferForm',
    'reports',
    'reportslist',
    'settings',
    'settingsGeneral',
    'settingsSellers',
    'team',
    'insights',
  ],
  adminseller: [
    'dashboard',
    'product',
    'productIventory',
    'productsIventory',
    'productImport',
    'offerInventory',
    'offers',
    'offerForm',
    'orders',
    'orderHistory',
    'settings',
    'settingsGeneral',
    'team',
    'insights',
    'settingsAccount',
  ],
  isAdmin: false,
  addUserDialog: false,
  defaultUserHeader: [
    // { text: '', value: 'avatar' },
    { text: 'First Name', value: 'firstName' },
    { text: 'Last Name', value: 'lastName' },
    { text: 'Preferred Name', value: 'preferredName' },
    { text: 'Phone Number', value: 'phoneNumber' },
    { text: 'Email', value: 'email' },
    // { text: '', value: 'role' },
    { text: 'Status', value: 'status' },
    { text: 'Actions', value: 'actions' },
  ],
  userActionItems: [
    {
      text: 'Details',
      value: 'editOffer',
      icon: 'mdi-information',
      disabled: true,
    },
  ],
  userFilter: [
    {
      name: 'name',
      label: 'Name',
      type: 'string',
      op: 'eq',
    },
  ],
  userSearch: [
    { text: 'First Name', value: 'firstName' },
    { text: 'Last Name', value: 'lastName' },
    { text: 'Company Name', value: 'companyName' },
  ],
})

export const getters = {
  user(state) {
    return state.user
  },
  superadmin(state) {
    return state.superadmin
  },
  adminseller(state) {
    return state.adminseller
  },

  isSuperAdmin(state) {
    return state.user.roles
      ? state.user.roles.includes(ROLES.SUPER_ADMIN)
      : false
  },
  addUserDialog(state) {
    return state.addUserDialog
  },
  users(state) {
    return state.users
  },
  defaultUserHeader(state) {
    return state.defaultUserHeader
  },
  userActionItems(state) {
    return state.userActionItems
  },
  userFilter(state) {
    return state.userFilter
  },
  userSearch(state) {
    return state.userSearch
  },
}

export const mutations = {
  [SET_USER](state, payload) {
    state.user = payload
  },
  [SET_USERS](state, payload) {
    state.users = payload
  },
  [ADD_USER_DIALOG](state, payload) {
    state.addUserDialog = payload
  },
}

export const actions = {
  async [GET_USER]({ commit }) {
    try {
      commit(START_LOADING, true, { root: true })
      const { data } = await this.$getUser()
      commit(SET_USER, data)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },
  async [GET_USERS]({ commit }) {
    try {
      commit(START_LOADING, true, { root: true })
      const data = await this.$getUsers()
      commit(SET_USERS, data)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },
}
