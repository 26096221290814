//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from 'vuex'
import Toolbar from '../components/Toolbar.vue'
import {
  SHOW_ALERT_NOTIFICATION,
  SET_ALERT_NOTIFICATION,
  SET_USER,
} from '../utils/constant'
import PollenFeedback from '~/components/feedback/Feedback.vue'
import PollenError from '~/components/common/PollenError.vue'

export default {
  name: 'SideBar',
  components: {
    'tool-bar': Toolbar,
    'pollen-feedback': PollenFeedback,
    'pollen-error': PollenError,
  },
  data() {
    return {
      selectedSellerId: '',
      clipped: true,
      fixed: true,
      drawer: false,
      showTooltip: true,
      miniVariant: false,
      // user: [{ title: 'Profile' }, { title: 'Log out', action: 'logout' }],
      items: [
        {
          icon: 'mdi-home-outline',
          name: 'dashboard',
          title: 'Dashboard',
          to: '/dashboard',
          tooltip: {
            title: 'Dashboard Menu',
            msg: 'Real-time view of inventory, offers, orders, recovery rate, sustainability information, and pending action items.',
          },
        },
        {
          icon: 'mdi-package-variant-closed',
          name: 'product',
          title: 'Inventory',
          to: '/batch',
          tooltip: {
            title: 'Inventory Menu',
            msg: 'Add inventory individually or in bulk',
          },
          children: [
            // {
            //   to: '/batch/view?type=create',
            //   name: 'productCreate',
            //   title: 'Upload',
            //   icon: 'mdi-folder-plus',
            // },
            {
              to: '/batch',
              name: 'productIventory',
              title: 'Batch',
              icon: 'mdi-file-multiple-outline',
            },
            {
              to: '/product/products',
              name: 'productsIventory',
              title: 'Product',
              icon: 'mdi-list-box-outline',
            },
            {
              to: '/product/import',
              name: 'productImport',
              title: 'Bulk Upload',
              icon: 'mdi-upload-multiple',
            },
            {
              to: '/product/category',
              name: 'productCategory',
              title: 'Category Management',
              icon: 'mdi-shape-outline',
            },
            {
              to: '/product/brand',
              name: 'productBrand',
              title: 'Brand Management',
              icon: 'mdi-shape-outline',
            },
            // {
            //   to: '/product/lots',
            //   title: 'Allocation',
            //   icon: 'mdi-arrow-decision',
            // },
          ],
        },
        {
          icon: 'mdi-book-open-variant',
          name: 'productCatalog',
          feature: 'product-catalog',
          title: 'Catalog',
          to: '/product/catalogs',
          tooltip: {
            title: 'Catalog',
            msg: 'After uploading product(s) to inventory, create a catalog for buyers.',
          },
        },
        {
          icon: 'mdi-hand-coin-outline',
          name: 'offers',
          feature: 'offer',
          title: 'Offer',
          to: '/offer',
          tooltip: {
            title: 'Offer',
            msg: 'View, accept, or reject offers for your inventory in one place',
          },
          children: [
            {
              to: '/offer',
              name: 'offerInventory',
              title: 'Offer Database',
              icon: 'mdi-cart-arrow-down',
            },
            {
              to: '/offer/import',
              name: 'uploadOfferForm',
              title: 'Upload Offer Form',
              icon: 'mdi-upload-multiple',
            },
          ],
        },
        {
          icon: 'mdi-cart-outline',
          name: 'orders',
          feature: 'order',
          title: 'Orders',
          to: '/order',
          tooltip: {
            title: 'Orders',
            msg: 'Keep track of all your orders in one place.',
          },
          children: [
            {
              to: '/order',
              name: 'orderHistory',
              title: 'Order History',
              icon: 'mdi-cart-arrow-down',
            },
          ],
        },
        {
          icon: 'mdi-account-multiple',
          name: 'team',
          title: 'Team',
          to: '/team',
          tooltip: {
            title: 'Team Management',
            msg: 'Add team members from your company to help manage inventory liquidation',
          },
        },
        {
          icon: 'mdi-chart-box-outline',
          title: 'Reports',
          to: '/report',
          name: 'reports',
          feature: 'report',
          tooltip: {
            title: 'Reports',
            msg: ' ',
          },
          children: [
            {
              to: '/report',
              name: 'reportslist',
              title: 'Lists',
              icon: 'mdi-file-chart-outline',
            },
            {
              to: '/report/insights',
              name: 'insights',
              title: 'Insights',
              icon: 'mdi-chart-line',
            },
          ],
        },
        {
          icon: 'mdi-cog-outline',
          title: 'Settings',
          feature: 'setting',
          name: 'settings',
          tooltip: {
            title: 'Account Setting',
            msg: 'Set up and manage company, account and payment setting.',
          },
          children: [
            {
              icon: 'mdi-account-group-outline',
              name: 'settingsSellers',
              to: '/setting/sellers',
              title: 'Sellers',
            },
            {
              icon: 'mdi-domain',
              name: 'settingsGeneral',
              to: '/setting/company',
              title: 'Company',
            },
            {
              icon: 'mdi-account',
              name: 'settingsAccount',
              to: '/setting/account',
              title: 'Account',
            },
          ],
        },
        {
          icon: 'mdi-account',
          title: 'Buyers',
          to: '/buyer',
          name: 'buyers',
          tooltip: {
            title: 'Buyers',
            msg: ' ',
          },
        },
      ],
      right: true,
      title: 'Liquidation Management System',
    }
  },
  computed: {
    ...mapGetters([
      'appLoading',
      'appLoadingText',
      'showAlertNotification',
      'alertNotificationText',
      'features/getFlag',
    ]),
    ...mapGetters(`feature`, ['getFlag']),
    ...mapGetters('users', [
      'isSuperAdmin',
      'adminseller',
      'superadmin',
      'user',
    ]),
    userRoles() {
      return JSON.parse(localStorage.getItem('sellerInfo')).roles
    },
    sellerName() {
      if (this.user.impersonation && this.user.impersonation.seller) {
        return this.user.impersonation.seller.companyName
      }

      return ''
    },
    isImpersonating() {
      if (
        this.user.impersonation.active === true &&
        this.user.impersonation.seller
      ) {
        return true
      }

      return false
    },
  },
  // eslint-disable-next-line require-await
  async mounted() {
    if (!this.$auth.loggedIn) {
      this.$router.push('/')
    }
    setTimeout(() => {
      this.showTooltip = false
    }, 2000)
    this[SET_USER](JSON.parse(localStorage.getItem('sellerInfo')))

    this.loadItems()
  },
  methods: {
    ...mapMutations({
      openAlertNotification: SHOW_ALERT_NOTIFICATION,
      setAlertNoficationText: SET_ALERT_NOTIFICATION,
    }),
    ...mapMutations('users', [SET_USER]),
    loadItems() {
      const filteredItem = []
      let roleItem = ''
      if (this.isSuperAdmin) {
        roleItem = this.superadmin
        // eslint-disable-next-line array-callback-return
        this.items.map((f) => {
          if (roleItem.includes(f.name)) {
            if (f.children) {
              // eslint-disable-next-line array-callback-return
              const child = f.children.map((ch) => {
                if (roleItem.includes(ch.name)) {
                  return ch
                }
              })
              f.children = child.filter((c) => c !== undefined)
            }
            filteredItem.push(f)
          }
        })
      } else {
        roleItem = this.adminseller
        // eslint-disable-next-line array-callback-return
        this.items.map((f) => {
          if (roleItem.includes(f.name)) {
            if (f.children) {
              // eslint-disable-next-line array-callback-return
              const child = f.children.map((ch) => {
                if (roleItem.includes(ch.name)) {
                  return ch
                }
              })
              f.children = child.filter((c) => c !== undefined)
            }
            filteredItem.push(f)
          }
        })
      }
      this.items = filteredItem.filter((s) => s !== undefined)
    },
    action(method) {
      this[method]()
    },
    logout() {
      this.$router.push('/logout')
    },
  },
}
