import {} from '../utils/constant'
export const state = () => ({
  defaultHeader: [
    { text: 'Product Name', value: 'productName' },
    // {text: "SKU Number", value: "skuNumber"},
    // {text: "SKU Name", value: "skuName"},
    // {text: "Pack Size (Number)", value: "packSizeNum"},
    // {text: "Pack Size (Unit)", value: "packSizeUnit"},
    // {text: "Barcode Number", value: "barcode"},
    // {text: "Bar Code format (EAN or UPC)", value: "barcodeFormat"},
    // {text: "Variant", value: "variant"},
    // {text: "Size", value: "size"},
    // {text: "Colour", value: "colour"},
    // {text: "Brand", value: "brand"},
    // {text: "Product Sub Category", value: "productSubCategory"},
    // {text: "Product Catagory", value: "productCategory"},
    { text: '', value: 'data-table-expand' },
    // { text: 'Actions', value: 'actions' },
  ],
  hiddenHeader: ['variant', 'size', 'colour'],
  productsSearch: [
    { text: 'Product Name', value: 'productName' },
    { text: 'SKU Name', value: 'skuName' },
    { text: 'SKU Number', value: 'skuNumber' },
  ],
  productSaved: 0,
  productCatalogHeader: [
    { text: 'Product Name', value: 'productName', width: '35%' },
    { text: 'SKU Number', value: 'skuNumber' },
    { text: 'Product Category', value: 'productCategory' },
    // { text: 'Product Sub Category', value: 'productSubCategory' },
    { text: 'Total Inventory', value: 'totalUnits' },
    // { text: 'Warehouse Location', value: 'warehouseLocation' },
    { text: '', value: 'data-table-expand', width: '5%', sortable: false },
  ],
})

export const getters = {
  productsSearch(state) {
    return state.productsSearch
  },
  productCatalogHeader(state) {
    return state.productCatalogHeader
  },
}

export const mutations = {}

export const actions = {}
