import { posthog } from 'posthog-js'
import Vue from 'vue'
import { FEATURE_FLAG_ACTION } from '~/store/feature'

export default function (instance, inject) {
  // Init PostHog
  const {
    app: { router },
    $config,
    store,
  } = instance
  posthog.init($config.POSTHOG_API_KEY, {
    capture_pageview: false,
  })

  inject('posthog', posthog)
  router.afterEach((to) => {
    Vue.nextTick(() => {
      posthog.capture('$pageview', {
        $current_url: to.fullPath,
      })
    })
  })
  posthog.onFeatureFlags((_flags, variants) => {
    store.dispatch(FEATURE_FLAG_ACTION, variants)
  })
}
