import {
  SET_FILE_ID,
  UPLOAD_FILE_S3,
  START_LOADING,
  SET_LOADING_TEXT,
  STOP_LOADING,
  SUCCESS,
  SET_FILE_STATUS,
  SET_FILE,
  SET_IMAGE_ID,
  SET_FILE_CONTENT_NDA,
  SET_FILE_CONTENT_LOA,
  SET_FILE_CONTENT_SOA,
  SET_FILE_CONTENT_OTHER,
  SET_FILE_TEMPLATE,
  SET_FILE_MSDS,
} from '../utils/constant'

export const state = () => ({
  file: null,
  fileId: null,
  fileStatus: false,
  fileTempates: {
    prepackage:
      'https://s3.ap-southeast-1.amazonaws.com/assets.mypollenstore.com/templates/Product+Template+(Pre-Packaged).xlsx',
    nonpackage:
      'https://s3.ap-southeast-1.amazonaws.com/assets.mypollenstore.com/templates/Product+Template+(Retail_Non-Package).xlsx',
    food_prepackage:
      'https://s3.ap-southeast-1.amazonaws.com/assets.mypollenstore.com/templates/Food+Template+(Pre-Packaged).xlsx',
    food_nonpackage:
      'https://s3.ap-southeast-1.amazonaws.com/assets.mypollenstore.com/templates/Food+Template+(Retail_Non-Package).xlsx',
    batch:
      'https://s3.ap-southeast-1.amazonaws.com/assets.mypollenstore.com/templates/Batch+Template.xlsx',
    general:
      'https://s3.ap-southeast-1.amazonaws.com/assets.mypollenstore.com/templates/General+Template.xlsx',
    variant:
      'https://s3.ap-southeast-1.amazonaws.com/assets.mypollenstore.com/templates/Variant+Template.xlsx',
    offer:
      'https://s3.ap-southeast-1.amazonaws.com/assets.mypollenstore.com/templates/Offer+Template.xlsx',
  },
  fileImage: null,
  fileContentNDA: null,
  fileContentLoA: null,
  fileContentSoA: null,
  fileContentOther: null,
  fileUploadTemplate: null,
  fileContentMSDS: null,
})

export const getters = {
  fileId(state) {
    return state.fileId
  },
  fileStatus(state) {
    return state.fileId
  },
  fileTemplates(state) {
    return state.fileTempates
  },
  file(state) {
    return state.file
  },
  fileImage(state) {
    return state.fileImage
  },
  fileContentNDA(state) {
    return state.fileContentNDA
  },
  fileContentLoA(state) {
    return state.fileContentLoA
  },
  fileContentSoA(state) {
    return state.fileContentSoA
  },
  fileContentOther(state) {
    return state.fileContentOther
  },
  fileUploadTemplate(state) {
    return state.fileUploadTemplate
  },
  fileContentMSDS(state) {
    return state.fileContentMSDS
  },
}

export const mutations = {
  [SET_FILE_ID](state, payload) {
    state.fileId = payload
  },
  [SET_FILE_STATUS](state, payload) {
    state.fileStatus = payload
  },
  [SET_IMAGE_ID](state, payload) {
    if (payload) {
      state.fileImage = {
        fileId: payload.fileId || payload.id,
        s3src: payload.uploadFileUrl?.data.files.s3_path || payload.path,
        imageSrc: payload.imageSrc || payload.path,
      }
    } else {
      state.fileImage = null
    }
  },
  [SET_FILE_CONTENT_NDA](state, payload) {
    if (payload) {
      state.fileContentNDA = {
        fileId: payload.fileId || payload.id,
        s3src: payload.uploadFileUrl?.data.files.s3_path || payload.path,
        imageSrc: payload.imageSrc || payload.mime,
        name: payload.filename || payload.name,
      }
    } else {
      state.fileContentNDA = null
    }
  },
  [SET_FILE_CONTENT_LOA](state, payload) {
    if (payload) {
      state.fileContentLoA = {
        fileId: payload.fileId || payload.id,
        s3src: payload.uploadFileUrl?.data.files.s3_path || payload.path,
        imageSrc: payload.imageSrc || payload.mime,
        name: payload.filename || payload.name,
      }
    } else {
      state.fileContentLoA = null
    }
  },
  [SET_FILE_CONTENT_SOA](state, payload) {
    if (payload) {
      state.fileContentSoA = {
        fileId: payload.fileId || payload.id,
        s3src: payload.uploadFileUrl?.data.files.s3_path || payload.path,
        imageSrc: payload.imageSrc || payload.mime,
        name: payload.filename || payload.name,
      }
    } else {
      state.fileContentSoA = null
    }
  },
  [SET_FILE_CONTENT_OTHER](state, payload) {
    if (payload) {
      state.fileContentOther = {
        fileId: payload.fileId || payload.id,
        s3src: payload.uploadFileUrl?.data.files.s3_path || payload.path,
        imageSrc: payload.imageSrc || payload.mime,
        name: payload.filename || payload.name,
      }
    } else {
      state.fileContentOther = null
    }
  },
  [SET_FILE_MSDS](state, payload) {
    if (payload) {
      state.fileContentMSDS = {
        fileId: payload.fileId || payload.id,
        s3src: payload.uploadFileUrl?.data.files.s3_path || payload.path,
        imageSrc: payload.imageSrc || payload.mime,
        name: payload.filename || payload.name,
      }
    } else {
      state.fileContentMSDS = null
    }
  },
  [SET_FILE](state, payload) {
    state.file = payload
  },
  [SET_FILE_TEMPLATE](state, payload) {
    state.fileUploadTemplate = payload
  },
}

export const actions = {
  async [UPLOAD_FILE_S3]({ commit }, payload) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Generating secure url...', { root: true })
      const { filename, contentType, file, imageSrc, fileCategory } = payload
      const { url, fileId, uploadedUrl } = await this.$uploadUrl({
        filename,
        contentType,
        fileCategory,
      })
      commit(SET_LOADING_TEXT, 'Uploading file...', { root: true })
      const uploadFile = await this.$uploadFileToServer(url, file)
      commit(SET_FILE_STATUS, true)
      if (uploadFile) {
        commit(SET_LOADING_TEXT, 'Updating file status...', { root: true })
        const uploadFileUrl = await this.$updateFileStatus({
          fileId,
          status: SUCCESS,
        })
        commit(SET_FILE_ID, fileId)
        if (uploadFileUrl) {
          if (contentType.match('pdf')) {
            switch (fileCategory) {
              case 'company_document_nda':
                commit(SET_FILE_CONTENT_NDA, {
                  fileId,
                  uploadFileUrl,
                  imageSrc,
                  filename,
                })
                break
              case 'company_document_loa':
                commit(SET_FILE_CONTENT_LOA, {
                  fileId,
                  uploadFileUrl,
                  imageSrc,
                  filename,
                })
                break
              case 'company_document_soa':
                commit(SET_FILE_CONTENT_SOA, {
                  fileId,
                  uploadFileUrl,
                  imageSrc,
                  filename,
                })
                break
              case 'company_document_other':
                commit(SET_FILE_CONTENT_OTHER, {
                  fileId,
                  uploadFileUrl,
                  imageSrc,
                  filename,
                })
                break
              case 'product_msds_file':
                commit(SET_FILE_MSDS, {
                  fileId,
                  uploadFileUrl,
                  imageSrc,
                  filename,
                })
                break
              default:
                throw new Error('Invalid company document type')
            }
          } else {
            commit(SET_IMAGE_ID, { fileId, uploadFileUrl, imageSrc })
          }
        }
        return { url, fileId, uploadedUrl }
      }
    } catch (error) {
      commit(SET_FILE_STATUS, false, { root: true })
      throw error
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  [SET_FILE_CONTENT_NDA]({ commit }, payload) {
    try {
      commit(SET_FILE_CONTENT_NDA, payload)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },
  [SET_FILE_CONTENT_LOA]({ commit }, payload) {
    try {
      commit(SET_FILE_CONTENT_LOA, payload)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  [SET_FILE_CONTENT_SOA]({ commit }, payload) {
    try {
      commit(SET_FILE_CONTENT_SOA, payload)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  [SET_FILE_CONTENT_OTHER]({ commit }, payload) {
    try {
      commit(SET_FILE_CONTENT_OTHER, payload)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  [SET_IMAGE_ID]({ commit }, payload) {
    try {
      commit(SET_IMAGE_ID, payload)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },
  [SET_FILE_MSDS]({ commit }, payload) {
    try {
      commit(SET_FILE_MSDS, payload)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },
}
