import Vue from 'vue'
// register global filter so it can be use in the entire application
Vue.filter('float', function (value, digits) {
  if (!value) return ''
  return parseFloat(value).toFixed(digits)
})

Vue.filter('formatDate', function (value) {
  if (!value) return ''
  return new Date(value).toLocaleDateString()
})

Vue.filter('formatCurrency', function (n, locale, currency) {
  if (!n) return 0

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency || 'USD',
  }).format(n)
})

Vue.filter('withComma', function (value) {
  if (!value) return 0

  return value.toLocaleString()
})

Vue.filter('formatCapitalize', function (value) {
  if (!value) return ''
  value = value.toLowerCase()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('arrayToString', function (value, attr) {
  if (!value || !value[attr]) return 'n/a'
  return value[attr].join(', ')
})
