const ACTION = 'FEATURE_FLAG'
export const FEATURE_FLAG_ACTION = `feature/${ACTION}`

export const state = () => ({
  /**
   * @type {Record<string, boolean>}
   */
  features: {},
})

export const mutations = {
  setFeatures(state, features) {
    state.features = features
  },
}

export const actions = {
  [ACTION]({ commit }, flags) {
    commit('setFeatures', flags)
  },
}

export const getters = {
  getFlag:
    (state) =>
    /**
     * check enabled feature
     * @param {string} flag
     * @returns
     */
    (flag) => {
      // feature is enabled by default
      if (!flag) return true
      return state.features[flag]
    },
}
