import {
  DEFAULT_ERROR_MESSAGE,
  SHOW_ALERT_NOTIFICATION,
  SET_ALERT_NOTIFICATION,
  SET_ALERT_NOTIFICATION_COLOR,
} from './../utils/constant'

function getMessageKeys(obj) {
  const messageKeys = []
  for (const key in obj) {
    if (key === 'message') {
      if (typeof obj[key] === 'object') {
        const nestedMessageKeys = getMessageKeys(obj[key])
        messageKeys.push(...nestedMessageKeys)
      } else {
        messageKeys.push(obj[key])
      }
    } else if (typeof obj[key] === 'object') {
      const nestedMessageKeys = getMessageKeys(obj[key])
      messageKeys.push(...nestedMessageKeys)
    }
  }
  return messageKeys
}

const parseErrorMessage = (err) => {
  if (err.response.data) {
    const messages = getMessageKeys(err.response.data)
    if (messages.length > 0) {
      return messages.join('\n')
    }
  }

  return (
    DEFAULT_ERROR_MESSAGE[err.response.status] || DEFAULT_ERROR_MESSAGE[500]
  )
}

export default function ({ $axios, $config, store, redirect }) {
  $axios.onError((error) => {
    store.commit(SET_ALERT_NOTIFICATION, parseErrorMessage(error))
    store.commit(SHOW_ALERT_NOTIFICATION, true)
    store.commit(SET_ALERT_NOTIFICATION_COLOR, 'red')
    if (error.response.status === 412) {
      setTimeout(() => {
        store.commit(SHOW_ALERT_NOTIFICATION, false)
        redirect('/dashboard')
      }, duration)
    }
    if (error.response.status === 500) {
      setTimeout(() => {
        store.commit(SHOW_ALERT_NOTIFICATION, false)
      }, duration)
    }
    if (error.response.status === 400) {
      setTimeout(() => {
        store.commit(SHOW_ALERT_NOTIFICATION, false)
      }, duration)
    }
    if (error.response.status === 401) {
      setTimeout(() => {
        store.commit(SHOW_ALERT_NOTIFICATION, false)
        redirect('/login')
      }, duration)
    }
    if (error.response.status === 401)
      setTimeout(() => store.commit(SHOW_ALERT_NOTIFICATION, false), 2000)
  })
  $axios.interceptors.request.use((req) => {
    // auth related handlers
    if (
      req.url.includes($config.AUTH0_DOMAIN) &&
      req.url.includes('userinfo')
    ) {
      req.url = `${$config.API_ENDPOINT}/users/me`
    }
    if (!req.url.includes('oauth/token')) {
      $axios.setHeader('Content-Type', 'application/json')
    }
    return req
  })
}
