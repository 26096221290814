const GENERAL_TEMPLATE = {
  // [Key] : [actual key in the entity]
  'SKU Number': 'skuNumber',
  'Expiry Date': 'expiryDate',
  'Manufacturing Date': 'manufacturingDate',
  '<Package type>': 'packagingType',
  'Qty of <Package Type>': 'quantity',
  'Batch Condition': 'batchCondition',
  'SKU Name': 'skuName',
  'Product Name': 'productName',
  'Items per <Package Type>': 'itemsPerPackageType',
  'Pack Size (Number)': 'packSizeNum',
  'Pack Size (Unit)': 'packSizeUnit',
  'Barcode Number': 'barcode',
  '<Package Type> Barcode Number': 'packageTypeBarcode',
  'Bar Code format (EAN or UPC)': 'barcodeFormat',
  Brand: 'brand',
  'Product Sub Category': 'productSubCategory',
  'Manufactured Country': 'manufacturedCountry',
  'Warehouse Location ': 'warehouseLocation',
  'Language Label': 'itemLanguageLabel',
  'CBM per Package Type': 'cbmPerPackageType',
  'Package Dimensions per <Package Type> (Length)': 'packageDimensionLength',
  'Package Dimensions per <Package Type> (Width)': 'packageDimensionWidth',
  'Package Dimensions per <Package Type> (Height)': 'packageDimensionHeight',
  'Unit Dimension': 'unitDimension',
  'Gross Weight per <Package Type> (KG)': 'grossWeightPerPackage',
  'Net Weight per Package Type (KG)': 'netWeightPerPackage',
  '# <of Package Type> Per Pallet': 'numberPackagePerPallet',
  'Retail Price per <Package Type>': 'retailPricePerPackageType',
  'Minimum Accepted Price per <Package Type>': 'minimumAcceptedPricePerPackage',
  'Asking Price per <Package Type>': 'askingPricePerPackageType',
  Currency: 'currency',
  'Dangerous Goods\n (Y/N)': 'isDangerousGoods',
  'Batch Restricted Country': 'batchRestrictedCountry',
  Country: 'country',
  'MSDS File (PDF)': 'msdsFile',
}

const VARIANT_TEMPLATE = {
  // [Key] : [actual key in the entity]
  ...GENERAL_TEMPLATE,
  Variant: 'variant',
  Size: 'size',
  Colour: 'colour',
}

export const OFFER_TEMPLATE = {
  Name: 'name',
  'SKU Number': 'skuNumber',
  'Expiry Date': 'expiryDate',
  'Warehouse Location': 'warehouseLocation',
  'Qty of the <Package Type> (Offered)': 'quantity',
  'Offer Price per <Package Type>': 'offerPricePerPackage',
  'Offer Price Currency': 'offerPriceCurrency',
  Buyer: 'buyer',
  'Product Catalog ID': 'catalog',
  Status: 'status',
  Timestamp: 'timestamp',
}

export const COMMON_PRODUCT_TEMPLATE = {
  'SKU Number': 'skuNumber',
  'Product Condition': 'productCondition',
  'SKU Name': 'skuName',
  'Product Name': 'productName',
  Brand: 'brand',
  'Product Sub Category': 'productSubCategory',
  'Variant Integration Number': 'variantIntegrationNumber',
  'Option for Variation 1': 'optionForVariation1',
  'Variation Name 1': 'variationName1',
  'Option for Variation 2': 'optionForVariation2',
  'Variation Name 2': 'variationName2',
  'Variant SKU': 'variantSku',
  'Selling Unit': 'sellingUnit',
  'Available Unit': 'availableUnit',
  'Item Size (Number)': 'packSizeNum',
  'Item Size (Unit)': 'packSizeUnit',
  'Net Weight per Item (KG)': 'netWeightPerItem',
  'Net Weight of Item (KG)': 'netWeightPerItem',
  'Item Language Label': 'itemLanguageLabel',
  'Unit Dimension': 'unitDimension',
  'Manufactured Country': 'manufacturedCountry',
  'Warehouse Location': 'warehouseLocation',
  'Retail Price per <Unit>': 'retailPricePerUnit',
  'Minimum Accepted Price per <Unit>': 'minimumAcceptedPricePerUnit',
  'Asking Price per <Unit>': 'askingPricePerUnit',
  'Product Barcode Number': 'barcode',
  'Bar Code format (EAN or UPC)': 'barcodeFormat',
  'Dangerous Goods (Y/N)': 'isDangerousGoods',
  'Product Restricted Country': 'batchRestrictedCountry',
  'MSDS File (PDF)': 'msdsFile',
}
export const FOOD_PRODUCT_TEMPLATE = {
  Storage: 'storage',
  'Certification Label': 'certificationLabel',
}

export const PRE_PACKAGE_PRODUCT_TEMPLATE = {
  ...COMMON_PRODUCT_TEMPLATE,
  'Minimum Order Unit (MOQ)': 'minimumOrderUnit',
  '<Packaging type>': 'packagingType',
  'Item per Package Type': 'itemsPerPackageType',
  'Package Dimensions per <Package Type> (Length)': 'packageDimensionLength',
  'Package Dimensions per <Package Type> (Width)': 'packageDimensionWidth',
  'Package Dimensions per <Package Type> (Height)': 'packageDimensionHeight',
  'Gross Weight per <Package Type> (KG)': 'grossWeightPerPackage',
  'Net Weight per Package Type (KG)': 'netWeightPerPackage',
  '# <of Unit> Per Pallet': 'numberPackagePerPallet',
  '<Package Type> Barcode Number': 'packageTypeBarcode',
}

export const FOOD_PRE_PACKAGE_PRODUCT_TEMPLATE = {
  ...PRE_PACKAGE_PRODUCT_TEMPLATE,
  ...FOOD_PRODUCT_TEMPLATE,
}

export const NON_PACKAGE_PRODUCT_TEMPLATE = {
  ...COMMON_PRODUCT_TEMPLATE,
  'Minimum Order Quantity (MOQ)': 'minimumOrderQuantity',
  'Item Dimensions  (Length)': 'itemDimensionLength',
  'Item Dimension(Width)': 'itemDimensionWidth',
  'Item Dimension (Height)': 'itemDimensionHeight',
}

export const FOOD_NON_PACKAGE_PRODUCT_TEMPLATE = {
  ...NON_PACKAGE_PRODUCT_TEMPLATE,
  ...FOOD_PRODUCT_TEMPLATE,
}

const BATCH_TEMPLATE = {
  // [Key] : [actual key in the entity]
  'SKU Number': 'skuNumber',
  'Barcode Number': 'barcode',
  'Expiry Date': 'expiryDate',
  'Manufacturing Date': 'manufacturingDate',
  'Available Unit': 'quantity',
  'Minimum Order Quantity (MOQ)': 'minimumOrderQuantity',
  'Minimum Accepted Price per <Unit>': 'minimumAcceptedPrice',
  'Retail Price per <Unit>': 'retailPrice',
  'Asking Price per <Unit>': 'askingPrice',
  'Warehouse Location ': 'warehouseLocation',
}

export const CSV_FORMATS = {
  GENERAL_TEMPLATE,
  VARIANT_TEMPLATE,
  PRE_PACKAGE_PRODUCT_TEMPLATE,
  NON_PACKAGE_PRODUCT_TEMPLATE,
  FOOD_PRE_PACKAGE_PRODUCT_TEMPLATE,
  FOOD_NON_PACKAGE_PRODUCT_TEMPLATE,
  BATCH_TEMPLATE,
}
export const DEFAULT_ERROR_MESSAGE = {
  400: 'Bad Request: Please check the data you are sending.',
  401: `Not Authenticated: Sorry, you have to be logged in to access the app.`,
  403: `Not Authorized: Sorry, you are not allowed to access this functionality`,
  404: `Not Found: We couldn't find what you're looking for.`,
  422: 'Validation Error',
  500: 'Server Error: Please contact the support team.',
  412: 'This feature is disabled',
}

export const VALIDATION_MESSAGE = {
  REQUIRED_FIELDS: 'Please fill out all required fields',
  SUCCESS_UPDATE: 'Update Successfully!',
  COMPANY_LOGO: 'Please select JPG/JPEG image',
  COMPANY_DOCUMENT: 'Please select PDF file',
  SUCCESS_CREATE_CATALOG: 'Product Catalog created successfully!',
  SUCCESS_CREATE_BRAND: 'Product Catalog created successfully!',
  SUCCESS_CREATE_CATEGORY: 'Product Catalog created successfully!',
  REQUIRED_CATEGORY: 'Category name should not be null',
  REQUIRED_BRAND: 'Brand name should not be null',
  CHECK_SUB_CATEGORY: 'Please check Sub-Category name value',
  CHECK_BRAND: 'Please check Brand name value',
  CATALOG: 'Please select catalog',
  SUCCESS_CATALOG_LIVE_UPDATE: 'Catalog Live updated successfully!',
}

export const ROLES = {
  SUPER_ADMIN: 'superAdmin',
  ADMIN: 'Admin',
  REGIONAL: 'regional',
  USER_FINANCE: 'userFinance',
  USER_OPERATION: 'userOperation',
}

export const FEATURES = {
  DELETE_INVENTORY: 'delete-inventory',
  PRODUCT_CATALOG: 'product-catalog',
  OFFER: 'offer',
  ORDER: 'order',
  REPORTS: 'reports',
  TEAMS: 'teams',
  PRODUCT: 'product',
  BATCH: 'batch',
  BULK_UPLOAD: 'bulk-upload',
  SETTING: 'setting',
  PRODUCT_BATCH_CATALOG: 'product-batch-catalog',
}

// For orders module store
export const GET_ORDER_LIST = 'getOrderList'

// For offers module store
export const GET_OFFER_LIST = 'getOfferList'
export const GET_OFFER_IMPORTS = 'getOfferImports'
export const SET_OFFER_IMPORTS = 'setOfferImports'
export const SET_OFFER_HEADERS = 'setOfferHeaders'
export const SET_OFFER_ERROR_SUMMARY = 'setOfferErrorSummary'
export const SET_OFFER_IMPORTS_COUNT = 'setOfferImportsCount'
export const VALIDATE_OFFERS = 'validateOffers'
export const PARSE_VALIDATE_OFFERS = 'parseAndValidateOffers'
export const REPLACE_OFFER_IMPORTS = 'replaceOfferImports'
export const SAVE_OFFER_IMPORTS = 'saveOfferImports'
export const SAVE_OFFER_IMPORTS_COUNT = 'saveOfferImportsCount'
export const SAVE_OFFER_BATCH_DRAFT = 'saveOfferBatchDraft'
export const SUBMIT_OFFER_BATCH = 'submitOfferBatch'
export const OFFERS_SELECTED = 'offersSelected'
export const OFFERS_SELECTED_DIALOG = 'offersSelectedDialog'
export const OFFERS_LIST_DIALOG = 'offersListDialog'
export const SET_OFFER_BATCH = 'setOfferBatch'

// For products module store
export const GET_PRODUCT_CATEGORIES = 'getProductCategories'
export const SET_PRODUCT_IMPORTS = 'getProductImports'
export const GET_PRODUCT_INVENTORY = 'getProductInventory'
export const GET_PRODUCT_VALIDATE_REQUIRED = 'getProductValidatedRequired'
export const GET_PRODUCT_VALIDATE_DUPLICATE = 'getProductValidatedDuplicate'
export const GET_PRODUCT_VALIDATED = 'getProductValidated'
export const PARSE_VALIDATE_PRODUCTS = 'parseAndValidateProducts'
export const SET_PRODUCT_HEADERS = 'setProductHeaders'
export const SET_PRODUCT_TEMPLATE = 'setProductTemplate'
export const SET_PRODUCT_ERROR_SUMMARY = 'setProductErrorSummary'
export const SET_PRODUCT_DUPLICATE_SUMMARY = 'setProductDuplicateSummary'
export const DOWNLOAD_LOGS = 'downloadLogs'
export const SET_DOWNLOAD_LOG_URL = 'setDownloadLogUrl'
export const SORT_ARRAY = 'sortArray'
export const GET_PRODUCT_BRANDS = 'getProductBrands'
export const GET_PRODUCT_IMPORTS = 'getProductImports'
export const SAVE_PRODUCT_IMPORTS = 'saveBulkProducts'
export const SAVE_PRODUCT_IMPORTS_COUNT = 'savedProducts'
export const REPLACE_PRODUCT_IMPORTS = 'replaceProductImports'
export const CALCULATE_SELECTED_PRODUCTS = 'calculateSelectedProducts'
export const COMPUTE_SELECTED_PRODUCTS = 'computeCalculatedSelectedProducts'
export const PRODUCTS_SELECTED = 'productSelected'
export const PRODUCTS_SELECTED_DIALOG = 'productSelectedDialog'
export const GET_PRODUCT_COUNTRIES = 'getProductCountries'
export const GET_PRODUCT_COUNTRIES_CURRENCY = 'getProductCountriesCurrency'
export const SAVE_SINGLE_PRODUCT = 'saveSingleProduct'
export const SAVE_PRODUCT_COUNT = 'saveProductCount'
export const UPDATE_SINGLE_PRODUCT = 'updateSingleProduct'
export const SET_PRODUCT_PARAMS = 'setProductParams'
export const SAVE_SINGLE_BATCH = 'saveSingleBatch'
export const SAVE_BATCH = 'saveBatch'
export const UPDATE_SINGLE_BATCH = 'updateSingleBatch'
export const PRODUCTS_IMAGE_UPLOAD_DIALOG = 'productImageDialog'
export const UPLOAD_PRODUCT_IMAGE_FILE_S3 = 'uploadProductImageFile'
export const SAVE_PRODUCT_IMAGE = 'saveProductImageFile'
export const SET_PRODUCT_SELECTED = 'setProductSelected'
export const DELETE_PRODUCT_IMAGE = 'deleteProductImage'
export const GET_PRODUCT_ALL = 'getProductAll'
export const PRODUCT_BATCH_EXPAND = 'getProductBatchExpand'

// for batches store
export const SET_BATCH_IMPORTS = 'setBatchImports'
export const GET_BATCH_INVENTORY = 'getBatchImports'
export const PARSE_VALIDATE_BATCH = 'parseValidateBatch'
export const SET_BATCH_TEMPLATE = 'setBatchTemplate'
export const SET_BATCH_ERROR_SUMMARY = 'setBatchErrorSummary'
export const SET_BATCH_DUPLICATE_SUMMARY = 'setBatchDuplicateSummary'
export const SAVE_BATCH_IMPORTS = 'saveBulkBatch'
export const SAVE_BATCH_IMPORTS_COUNT = 'savedBatch'
export const REPLACE_BATCH_IMPORTS = 'replaceBatchImports'
export const SET_BATCH_HEADERS = 'setBatchHeaders'
export const DOWNLOAD_LOGS_BATCH = 'downloadLogsBatch'
export const SET_DOWNLOAD_LOG_URL_BATCH = 'setDownloadLogUrlBatch'
export const CALCULATE_SELECTED_BATCH = 'calculateSelectedBatch'
export const COMPUTE_SELECTED_BATCH = 'computeCalculatedSelectedBatch'
export const SORT_ARRAY_BATCH = 'sortArrayBatch'
export const GET_BATCH_WAREHOUSE_LOCATION = 'getBatchWarehouseLocation'

// For main store
export const START_LOADING = 'startLoading'
export const STOP_LOADING = 'stopLoading'
export const SET_LOADING_TEXT = 'setLoadingText'
export const UPLOADING_TEXT = 'Loading...'

// For files module store
export const SET_FILE_ID = 'setFileId'
export const SET_IMAGE_ID = 'setImageId'
export const SET_FILE = 'setFile'
export const GET_UPLOAD_URL = 'getUploadUrl'
export const PARSE_MANIFEST_FILE = 'parseMAFile'
export const UPLOAD_FILE_S3 = 'uploadFileToS3'
export const UPLOAD_MULTIPLE_FILE_S3 = 'uploadMultipleFileToS3'
export const SUCCESS = 'SUCCESS'
export const FAILED = 'FAILED'
export const SET_FILE_STATUS = 'setFileStatus'
export const DELETE_IMAGE = 'deleteImage'
export const SET_FILE_CONTENT_NDA = 'setFileContentNDA'
export const SET_FILE_CONTENT_LOA = 'setFileContentLoA'
export const SET_FILE_CONTENT_SOA = 'setFileContentSoA'
export const SET_FILE_CONTENT_OTHER = 'setFileContentOther'
export const SET_FILE_TEMPLATE = 'setFileUploadTemplate'
export const SET_FILE_MSDS = 'setFileMSDS'

// For lot module store
export const GET_LOT_INVENTORY = 'getLotInventory'
export const SET_LOT_ID = 'setLotId'
export const SAVE_LOT = 'saveLots'
export const SAVE_LOT_IMAGE = 'saveLotImage'
export const LOT_PRODUCT_DIALOG = 'lotProductSelectedDialog'

// For alert module store
export const GET_ALERT_NOTIFICATION = 'getAlertNotification'
export const SET_ALERT_NOTIFICATION = 'setAlertNotification'
export const SHOW_ALERT_NOTIFICATION = 'showAlertNotification'
export const SHOW_IMPERSONATION = 'showImpernationAlert'
export const SET_ALERT_NOTIFICATION_CODE = 'setAlertNotificationCode'
export const SET_ALERT_NOTIFICATION_COLOR = 'setAlertNoticationColor'

// FOR general error
export const SHOW_ALERT = 'showAlert'
export const ALERT_MESSAGE = 'alertMessage'

// FOR dashboard store
export const GET_STATISTICS = 'getStatistics'
export const GET_TOP_PRIORITIES = 'getTopPriorities'
export const GET_SLOB_INDEX = 'getSlobIndex'
export const GET_DATE_LAST_UPLOADED = 'getDateLastUploaded'

// For catalog store
export const PRODUCTS_LIST_DIALOG = 'productListDialog'
export const SAVE_PRODUCT_CATALOG_COUNT = 'saveCatalogs'
export const SAVE_PRODUCT_CATALOG = 'saveProductCatalogs'
export const GET_ALL_PRODUCT_CATALOG = 'getAllProductCatalogs'
export const GET_CATALOG_URL = 'getCatalogUrl'
export const GET_CATALOG = 'getCatalog'
export const DELETE_PRODUCT_CATALOG = 'deleteCatalogs'
export const SET_CATALOG_PARAMS = 'setCatalogParams'
export const SET_CATALOG_LIVE = 'setCatalogLive'

// For seller store
export const SAVE_SELLER_GENERAL = 'saveSellerGeneral'
export const GET_SELLER_GENERAL = 'getSellerGeneral'
export const GET_SELLERS = 'getSellers'
export const SET_SELLERS = 'getSellers'
export const SET_SELLER = 'getSeller'
export const GET_TIMEZONE = 'getTimezone'
export const SET_SELLER_PARAMS = 'setSellerParams'
export const SET_IMPERSONATION = 'setImpersonation'
export const UPDATE_IMPERSONATION = 'updateImpersonation'
export const CANCEL_IMPERSONATION = 'cancelImpersonation'
export const SET_IMPERSONATION_VALUE = 'setImpersonationValue'
export const UPDATE_IMPERSONATION_VALUE = 'updateImpersonationValue'
export const GET_SELLER_LIST = 'getSellerList'
export const SET_SELLER_DOWNLOAD_URL = 'setSellerDownloadUrl'

// For currency store
export const GET_CURRENCY = 'getCurrency'
export const CURRENCY_EXCHANGE_USD = 'usdCurrencyExchange'

// For user store
export const GET_USER = 'getUser'
export const SET_USER = 'setUser'

export const GET_USERS = 'getUsers'
export const SET_USERS = 'setUsers'

// For report store
export const UPLOAD_REPORT_DIALOG = 'uploadReportDialog'
export const SAVE_REPORT = 'saveReport'
export const SAVE_REPORT_COUNT = 'saveReportCount'
export const GET_REPORTS = 'getReports'
export const GET_EMBED_URL = 'getEmbedUrl'

export const ADD_USER_DIALOG = 'addUserRoleDialog'
export const GET_ROLES = 'getRoles'
export const SET_ROLES = 'setRoles'

export const BATCHES_IMAGE_UPLOAD_DIALOG = 'batchesImageUploadDialog'
export const UPLOAD_BATCH_IMAGE_FILE_S3 = 'uploadBatchImageFileS3'
export const SAVE_BATCH_IMAGE = 'saveBatchImage'
export const DELETE_BATCH_IMAGE = 'deleteBatchImage'
export const SET_BATCH_SELECTED = 'setBatchSelected'

export const BATCH_HEADER = [
  {
    text: 'General Information',
    value: 'generalInformation',
    children: [
      { text: 'SKU Number', value: 'skuNumber' },
      { text: 'Product Condition', value: 'productCondition' },

      { text: 'SKU Number', value: 'skuNumber' },

      { text: 'Product Condition', value: 'productCondition' },
      { text: 'SKU Name', value: 'skuName' },
      { text: 'Product Name', value: 'productName' },
      { text: 'Product Category', value: 'productCategory' },
      { text: 'Product Sub Category', value: 'productSubCategory' },
      { text: 'Brand', value: 'brand' },
      { text: 'Submitted At', value: 'createdAt' },
      { text: 'Last Updated', value: 'updatedAt' },
      { text: 'Updated By', value: 'updatedBy' },
      { text: 'Manufactured Country', value: 'manufacturedCountry' },
      { text: 'Warehouse Location', value: 'warehouseLocation' },
      { text: 'Barcode Number', value: 'barcode' },
      { text: 'Bar Code format (EAN or UPC)', value: 'barcodeFormat' },
      { text: 'Product Restricted Country', value: 'batchRestrictedCountry' },
      { text: 'Additional', value: 'additionalLabels' },
    ],
  },
  {
    text: 'General Information',
    value: 'generalInformation',
    children: [
      { text: 'Expiry Date', value: 'expiryDate' },
      { text: 'Manufacturing Date', value: 'manufacturingDate' },
      { text: 'Priority', value: 'priority' },
      { text: 'Selling Unit', value: 'sellingUnit' },
      { text: 'Available Unit', value: 'quantity' },
      { text: 'Minumum Order Quantity', value: 'minumumOrderQuantity' },
      { text: 'Pack Size (Number)', value: 'packSizeNum' },
      { text: 'Pack Size (Unit)', value: 'packSizeUnit' },
      { text: 'Item Dimension Length', value: 'itemDimensionLength' },
      { text: 'Item Dimension Width', value: 'itemDimensionWidth' },
      { text: 'Item Dimension Height', value: 'itemDimensionHeight' },
      { text: 'Unit Dimension', value: 'unitDimension' },
      { text: 'Language Label', value: 'itemLanguageLabel' },
      { text: 'Dangerous Goods (Y/N)', value: 'isDangerousGoods' },
      { text: 'MSDS File (PDF)', value: 'msdsFile' },
      { text: 'Packaging Type', value: 'packagingType' },
      { text: 'Items per <Package Type>', value: 'itemsPerPackageType' },
      {
        text: 'Package Dimensions per <Package Type> (Length)',
        value: 'packageDimensionLength',
      },
      {
        text: 'Package Dimensions per <Package Type> (Width)',
        value: 'packageDimensionWidth',
      },
      {
        text: 'Package Dimensions per <Package Type> (Height)',
        value: 'packageDimensionHeight',
      },
      {
        text: 'Gross Weight per <Package Type> (KG)',
        value: 'grossWeightPerPackage',
      },
      {
        text: 'Net Weight per Package Type (KG)',
        value: 'netWeightPerPackage',
      },
      {
        text: '# <of Package Type> Per Pallet',
        value: 'numberPackagePerPallet',
      },
      { text: '# of Pallet', value: 'numberOfPallets' },
      { text: 'Total Items', value: 'totalItems' },
      { text: 'Total CBM', value: 'totalCbm' },
      { text: 'Total Gross Weight (KG)', value: 'totalGrossWeight' },
      { text: 'Total Net Weight (KG)', value: 'totalNetWeight' },
      { text: 'Shelf Life Remaining', value: 'shelfLifeRemainingDay' },
      { text: 'CBM', value: 'cbmPerPackageType' },
    ],
  },
  {
    text: 'General Information',
    value: 'generalInformation',
    children: [
      { text: 'Retail Price', value: 'retailPrice' },
      { text: 'Retail Price', value: 'retailPrice' },
      { text: 'Minimum Accepted Price', value: 'minimumAcceptedPrice' },
      { text: 'Asking Price', value: 'askingPrice' },
      { text: 'Cost Recovery', value: 'discountPercentage' },
      { text: 'Total Retail Price', value: 'totalRetailPrice' },
      { text: 'Total Asking Price', value: 'totalAskingPrice' },
      { text: 'Retail Price per Item', value: 'retailPricePerUnit' },
      { text: 'Asking Price per Item', value: 'askingPricePerUnit' },
      { text: 'Retail Price (USD)', value: 'retailPriceUsd' },
      {
        text: 'Minimum Accepted Price (USD)',
        value: 'minimumAcceptedPriceUsd',
      },
      { text: 'Asking Price (USD)', value: 'askingPriceUsd' },
      { text: 'Total Asking Price (USD)', value: 'totalAskingPriceUsd' },
      { text: 'Total Retail Price (USD)', value: 'totalRetailPriceUsd' },
      { text: 'Retail Price per Item (USD)', value: 'retailPricePerUnitUsd' },
      { text: 'Asking Price per Item (USD)', value: 'askingPricePerUnitUsd' },
    ],
  },
]

export const SORT_CATALOG = [
  { text: 'Product name', value: 'productName' },
  { text: 'SKU number', value: 'skuNumber' },
  { text: 'Product Sub Category', value: 'productSubCategory' },
  { text: 'Available Unit', value: 'quantity' },
  { text: 'Priority', value: 'priority' },
  { text: 'Asking Price', value: 'askingPrice' },
  { text: 'Discount', value: 'discountPercentage' },
]

export const COUNTRIES = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  "Côte d'Ivoire",
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo (Congo-Brazzaville)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czechia (Czech Republic)',
  'Democratic Republic of the Congo',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini (fmr. Swaziland)',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Holy See',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar (formerly Burma)',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Korea',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine State',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Korea',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States of America',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];
