export default ($axios) => {
  return {
    saveReport: async (body) => {
      return await $axios.post('/reports', body)
    },
    getReports: async () => {
      return await $axios.get('/reports')
    },
    getEmbedUrl: async () => {
      return await $axios.get('/reports/quicksight')
    },
  }
}
