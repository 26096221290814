export default ($axios) => {
  return {
    uploadUrl: async (params) => {
      const { data } = await $axios.post('/files/upload-url', params)
      return data
    },
    uploadFileToServer: async (url, file) => {
      return await $axios.put(url, file, {
        headers: {
          'Content-Type': file.type,
        },
        transformRequest: (data, headers) => {
          delete headers.common.Authorization
          delete headers.Authorization
          return data
        },
      })
    },
    updateFileStatus: async (params) => {
      return await $axios.put('/files/upload-url', params)
    },
  }
}
