import {
  START_LOADING,
  STOP_LOADING,
  SAVE_LOT,
  SAVE_LOT_IMAGE,
  GET_LOT_INVENTORY,
  SET_LOT_ID,
  SET_LOADING_TEXT,
  LOT_PRODUCT_DIALOG,
  UPLOAD_MULTIPLE_FILE_S3,
  SUCCESS,
  SET_FILE_STATUS,
  DELETE_IMAGE,
  SET_IMAGE_ID,
} from '../utils/constant'
export const state = () => ({
  lotSearchItem: [{ text: 'Lot Name', value: 'lotName', selected: true }],
  lotData: [],
  createLotHeaders: [
    { text: 'Product Name', value: 'productName' },
    { text: 'Location', value: 'countryManufactured' },
    { text: 'Expiry Date', value: 'expiryDate' },
    { text: 'Package qty', value: 'quantity' },
    { text: 'Allocate Package', value: 'allocation' },
    { text: 'Actions', value: 'actions', sortable: false },
  ],
  selectedProdHeaders: [
    { text: 'Product Name', value: 'productName' },
    // { text: 'Retail Price', value: 'retailPricePerPackageType' },
    { text: 'Quantity', value: 'allocation' },
    { text: 'Asking Price', value: 'askingPricePerPackageType' },
    { text: 'Total Asking Price', value: 'totalAskingPrice' },
    { text: 'Actions', value: 'actions' },
  ],
  lotPeriod: ['1 Day', '1 Week', '1 Month'],
  lotChannel: ['Pollen Stock', 'Pollen Save', 'Pollen Store'],
  lotInventory: [],
  lotProductsSelectedDialog: false,
  lotId: null,
  lotImage: [],
  fileStatus: false,
  lotHiddenColumn: [
    'id',
    'lotBatches',
    'isOfferable',
    'askingPrice',
    'retailPrice',
    'startDate',
    'endDate',
    'period',
    'createdAt',
    'deletedAt',
    'updatedAt',
  ],
  lotSearch: [{ text: 'Product Name', value: 'productName' }],
  lotFilter: [
    {
      name: 'listingType',
      label: 'Type',
      type: 'string',
      op: 'eq',
    },
    {
      name: 'channel',
      label: 'Channel',
      type: 'string',
      op: 'eq',
    },
    {
      name: 'status',
      label: 'Status',
      type: 'string',
      op: 'eq',
    },
    {
      name: 'askingPrice',
      label: 'Total Asking Price',
      type: 'number',
      op: 'gte',
    },
  ],
})

export const getters = {
  lotSearchItem(state) {
    return state.lotSearchItem
  },
  createLotHeaders(state) {
    return state.createLotHeaders
  },
  selectedProdHeaders(state) {
    return state.selectedProdHeaders
  },
  lotPeriod(state) {
    return state.lotPeriod
  },
  lotChannel(state) {
    return state.lotChannel
  },
  lotProductsSelectedDialog(state) {
    return state.lotProductsSelectedDialog
  },
  lotId(state) {
    return state.lotId
  },
  lotImage(state) {
    return state.lotImage
  },
  fileStatus(state) {
    return state.fileId
  },
  lotHiddenColumn(state) {
    return state.lotHiddenColumn
  },
  lotFilter(state) {
    return state.lotFilter
  },
  lotSearch(state) {
    return state.lotSearch
  },
}

export const actions = {
  async [SAVE_LOT]({ commit }, params) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Creating Lot. Please wait...', {
        root: true,
      })
      const req = await this.$saveLot(params.lotDetails)
      commit(SET_LOT_ID, req.id)
      commit(GET_LOT_INVENTORY, req)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [SAVE_LOT_IMAGE]({ commit }, params) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Saving Lot Image. Please wait...', {
        root: true,
      })
      const req = await this.$saveLotImage(params.imageDetails)
      commit(GET_LOT_INVENTORY, req)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [UPLOAD_MULTIPLE_FILE_S3]({ commit }, payload) {
    try {
      commit(START_LOADING, true, { root: true })
      commit(SET_LOADING_TEXT, 'Generating secure url...', { root: true })
      const { filename, contentType, file, imageSrc } = payload
      const { url, fileId } = await this.$uploadUrl({ filename, contentType })
      commit(SET_LOADING_TEXT, 'Uploading file...', { root: true })
      const uploadFile = await this.$uploadFileToServer(url, file)
      commit(SET_FILE_STATUS, true)
      if (uploadFile) {
        commit(SET_LOADING_TEXT, 'Updating file status...', { root: true })
        const uploadFileUrl = await this.$updateFileStatus({
          fileId,
          status: SUCCESS,
        })
        if (uploadFileUrl)
          commit(SET_IMAGE_ID, { fileId, uploadFileUrl, imageSrc })
      }
    } catch (error) {
      commit(SET_FILE_STATUS, false, { root: true })
      throw error
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },

  async [DELETE_IMAGE]({ commit }, params) {
    try {
      await this.$deleteLotImage(params.fileId)
      commit(DELETE_IMAGE, params)
    } catch (error) {
    } finally {
      commit(STOP_LOADING, false, { root: true })
    }
  },
}
export const mutations = {
  [GET_LOT_INVENTORY](state, payload) {
    state.lotInventory = payload
  },
  [SET_LOT_ID](state, payload) {
    state.lotId = payload
  },
  [LOT_PRODUCT_DIALOG](state, payload) {
    state.lotProductsSelectedDialog = payload
  },

  [SET_IMAGE_ID](state, payload) {
    if (payload) {
      state.lotImage.push({
        fileId: payload.fileId,
        s3src: payload.uploadFileUrl.data.files.s3_path,
        imageSrc: payload.imageSrc,
      })
    } else {
      state.lotImage = []
    }
  },
  [SET_FILE_STATUS](state, payload) {
    state.fileStatus = payload
  },

  [DELETE_IMAGE](state, payload) {
    state.lotImage = state.lotImage.filter(
      (arr) => arr.fileId !== payload.fileId
    )
  },
}
