import qs from 'querystring'
import * as axios from 'axios'
export default ($config) => {
  const posthogClient = axios.default.create({
    baseURL: 'https://app.posthog.com',
    headers: {
      Authorization: `Bearer ${$config.POSTHOG_TOKEN}`,
    },
  })
  return {
    posthogClient: {
      /**
       *
       * @param {object} param
       * @param {'-90d' | '-30d' | '-7d'} param.dateFrom
       * @param {'day' | 'week' | 'month'} param.interval
       * @param {Array} param.catalogs
       *
       */
      async getCatalogDownload(param) {
        const data = await posthogClient.get(
          `/api/projects/${
            $config.POSTHOG_PROJECT_ID
          }/insights/trend?${qs.stringify({
            date_from: param.dateFrom,
            interval: param.interval,
            events: JSON.stringify(
              param.catalogs.map((catalog) => ({
                id: 'download-catalog',
                name: `${catalog.name} - ${catalog.id}`,
                type: 'events',
                properties: [
                  {
                    key: 'path',
                    value: `/catalogs/export-data/${catalog.id}`,
                    operator: 'exact',
                  },
                ],
              }))
            ),
            display: 'ActionLineGraph',
          })}`
        )

        const result = {
          ...data.data,
          totalDownload: data.data.result.reduce(
            (prev, next) => prev + next.count,
            0
          ),
        }
        return result
      },
    },
  }
}
